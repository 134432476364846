import getConfiguration, {
  EnvironmentConfiguration,
} from '@/shared/configuration';
import {
  EventBus,
  getEventBus,
  viewedPaymentWidgetEvent,
  viewedStandardWidgetEvent,
  viewedLearnMoreModalEvent,
  viewedSecondChanceWidgetEvent,
  viewedCheckoutButtonEvent,
} from '@/services/events';
import logger from '@/shared/logger';
import { Fingerprint, getFingerprintAgent } from '@/services/fingerprinting';
import { Options } from './models';
import { validate as validateUuid } from 'uuid';

export class Analytics {
  private readonly eventBus: EventBus;
  private readonly configuration: EnvironmentConfiguration;
  private readonly fingerprintAgent: Fingerprint;
  private isInitialised = false;
  private fingerprint = '';
  private _merchantId = null;

  public get merchantId() {
    return this._merchantId;
  }

  public set merchantId(merchantId: string) {
    if (validateUuid(merchantId)) {
      this._merchantId = merchantId;
    }
  }

  constructor() {
    this.configuration = getConfiguration();
    this.eventBus = getEventBus();
    this.fingerprintAgent = getFingerprintAgent();
  }

  // Load the fingerprint and initiate all of the event subscribers
  async initialise(): Promise<void> {
    if (this.isInitialised) return;

    this.fingerprint = await this.fingerprintAgent.onReady();
    this.subscribeEvents();

    this.isInitialised = true;
  }

  private subscribeEvents() {
    this.eventBus.subscribeViewedStandardWidgetEvent(
      (
        amount: number,
        domain: string,
        path: string,
        loadingDuration: number,
        options: Options,
      ) => {
        const hasBeenPublished = sessionStorage.getItem(viewedStandardWidgetEvent);
        if (hasBeenPublished === 'true') {
          return;
        }

        this.handleEvent(viewedStandardWidgetEvent, {
          amount,
          domain,
          path,
          loadingDuration,
          options,
        });
      },
    );

    this.eventBus.subscribeViewedPaymentWidgetEvent(
      (
        amount: number,
        domain: string,
        path: string,
        loadingDuration: number,
        options: Options,
      ) => {
        const hasBeenPublished = sessionStorage.getItem(viewedPaymentWidgetEvent);
        if (hasBeenPublished === 'true') {
          return;
        }

        this.handleEvent(viewedPaymentWidgetEvent, {
          amount,
          domain,
          path,
          loadingDuration,
          options,
        });
      },
    );

    this.eventBus.subscribeViewedSecondChanceWidgetEvent(
      (
        amount: number,
        domain: string,
        path: string,
        loadingDuration: number,
        options: Options,
      ) => {
        const hasBeenPublished = sessionStorage.getItem(viewedSecondChanceWidgetEvent);
        if (hasBeenPublished === 'true') {
          return;
        }

        this.handleEvent(viewedSecondChanceWidgetEvent, {
          amount,
          domain,
          path,
          loadingDuration,
          options,
        });
      },
    );

    this.eventBus.subscribeViewedLearnMoreModalEvent(
      (
        amount: number,
        domain: string,
        path: string,
        widgetType: string,
      ) => {
        this.handleEvent(viewedLearnMoreModalEvent, {
          amount,
          domain,
          path,
          widgetType: widgetType,
        });
      },
    );

    this.eventBus.subscribeViewedCheckoutButtonEvent(
      (
        amount: number,
        domain: string,
        path: string,
        loadingDuration: number,
      ) => {
        const hasBeenPublished = sessionStorage.getItem(viewedCheckoutButtonEvent);
        if (hasBeenPublished === 'true') {
          return;
        }

        this.handleEvent(viewedCheckoutButtonEvent, {
          amount,
          domain,
          path,
          loadingDuration,
        });
      },
    );
  }

  private handleEvent(eventName: string, properties: any) {
    const eventBody = {
      eventName: eventName,
      anonymousId: this.fingerprint,
      merchantId: this.merchantId,
      ...properties,
    };

    this.publishEvent(eventBody);
  }

  // Publishes to Event Hub via the Analytics service
  private publishEvent(eventBody: any) {
    const url = `${this.configuration.analyticsHost}/widgetanalytics/metric`;
    const requestBody = JSON.stringify(eventBody);

    fetch(url, {
      method: 'post',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'origin',
      body: requestBody,
    })
      .then(async response => {
        if (!response.ok) {
          throw Error(`${response.status}: ${response.statusText}`);
        }

        sessionStorage.setItem(eventBody.eventName, 'true');
      })
      .catch(error => {
        logger.error(`Failed to publish ${eventBody.eventName} event to analytics service due to ${error.message}`);
      });
  }
}

let analytics: Analytics;

export const getAnalytics = (): Analytics => {
  if (!analytics) {
    analytics = new Analytics();
  }

  return analytics;
};
