import Translator from '@/shared/translator';
import {
  buttonCss,
  zipLogo,
  zipLogoBlack,
  grayLabelZipLogo,
} from '@/sdk-checkout-integration/assets';
import getConfiguration, {
  EnvironmentConfiguration,
  getConfigurationForGatewayDomainOverride,
} from '@/shared/configuration';
import virtualCheckout from '@/sdk-checkout-integration/virtual-checkout/virtual-checkout';
import {
  Card,
  Cardholder,
  CheckoutConfiguration,
  CheckoutFlow,
  VirtualSuccessCallback,
  Customer,
  LineItem,
  Order,
  EntrySource,
  ShippingPreference,
} from '@/sdk-checkout-integration/sdk-integration-models';
import logger from '@/shared/logger';
import fontCss from '@/shared/styles/font.scss';
import { ENTRY_SOURCE_KEY } from '@/services/storage';
import { getShippingPreference } from '@/utils/shippingLocation';
import { getEventBus } from '@/services/events';
import { getAnalytics } from '@/services/analytics';

const getCheckoutButtonTemplate = (buttonColor?: string) => {
  const buttonColorClass = buttonColor === 'white' ? 'checkout-button--white' : '';
  const logo = buttonColor === 'white' ? zipLogoBlack : zipLogo;

  return `
    <style>${buttonCss}</style>
    <button type="button" class="checkout-button ${buttonColorClass}">
      <span
        id="zip-button-text-pay-later"
        class="checkout-text"
        data-i18n="checkoutButton.text-pay-later"
      >Pay later with</span>
      ${logo}
    </button>
  `;
};

class QuadPayButton extends HTMLElement {
  configuration: EnvironmentConfiguration;
  checkoutButton: Element | null;
  navhelper: Element | null;
  shadow: ShadowRoot;
  translator: Translator;
  startLoad: number;
  loadTimeMilliseconds: number;

  constructor() {
    super();

    this.startLoad = performance.now();
    this.configuration = getConfiguration();

    this.translator = new Translator();

    // Try to grab the language from the document
    const language = document.querySelector('html')?.getAttribute('lang');
    if (language) {
      this.translator.setLanguage(language);
    }
  }

  connectedCallback() {
    // Unlike the constructor, connectedCallback() may fire multiple times so we need this guard
    if (!this.shadow) {
      const template = document.createElement('template');
      template.innerHTML = getCheckoutButtonTemplate(this.buttonColor);

      this.shadow = this.attachShadow({ mode: 'open' });
      this.shadow.appendChild(template.content.cloneNode(true));
    }

    if (!this.checkoutButton) {
      this.checkoutButton = this.shadow.querySelector('.checkout-button');

      // Validate if VC is set up from button props
      if (this.integrationType !== 'api') {
        virtualCheckout.validate(this.merchantId, this.buildOrder());
        this.checkoutButton.addEventListener('click', () => {
          this.openCheckout().catch(() => {});
        });
      }
    }

    // Using session storage to set entry source to be used by api-checkout
    if (this.entrySource !== EntrySource.SecondChance) {
      window.sessionStorage.setItem(ENTRY_SOURCE_KEY, EntrySource.ZipButton);
    }

    const fontStyle = document.createElement('style');
    fontStyle.innerHTML = `${fontCss}`;
    this.appendChild(fontStyle);

    if (this.configuration.isAnalyticsEnabled) {
      const eventBus = getEventBus();
      const analytics = getAnalytics();
      if (this.merchantId) analytics.merchantId = this.merchantId;

      this.loadTimeMilliseconds = Math.round(performance.now() - this.startLoad);

      // Only fire viewedCheckoutButtonEvent when at least 50% of the button is displayed
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
      const observer = new IntersectionObserver((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const amount = this.stringToNumber(this.amount);
            const domain = window.location.hostname;
            const path = window.location.pathname || '/';

            eventBus.publishViewedCheckoutButtonEvent(
              amount,
              domain,
              path,
              this.loadTimeMilliseconds,
            );

            observer.disconnect();
          }
        });
      }, options);

      observer.observe(this.checkoutButton);
    }

    // The setTimeout function is used to be able to run the translateComponent method as async in a synchronous context
    setTimeout(async () => {
      this.updateConfiguration();
      await this.translator.translateComponent(this.currency, this);
    }, 1);
  }

  // These are all available attributes that can be set on the web component element
  get buttonVerbiage() { return this.getAttribute('buttonVerbiage'); }
  get amount() { return this.getAttribute('amount'); }
  get shippingAmount() { return this.getAttribute('shippingAmount'); }
  get taxAmount() { return this.getAttribute('taxAmount'); }
  get currency() { return this.getAttribute('currency'); }
  get merchantId() { return this.getAttribute('merchantId'); }
  get merchantReference() { return this.getAttribute('merchantReference'); }
  get customerFirstName() { return this.getAttribute('customerFirstName'); }
  get customerLastName() { return this.getAttribute('customerLastName'); }
  get customerEmail() { return this.getAttribute('customerEmail'); }
  get customerPhoneNumber() { return this.getAttribute('customerPhoneNumber'); }
  get customerAddressLine1() { return this.getAttribute('customerAddressLine1'); }
  get customerAddressLine2() { return this.getAttribute('customerAddressLine2'); }
  get customerCity() { return this.getAttribute('customerCity'); }
  get customerState() { return this.getAttribute('customerState'); }
  get customerPostalCode() { return this.getAttribute('customerPostalCode'); }
  get gatewayURLOverride() { return this.getAttribute('gatewayURLOverride'); }
  get isDisabled() { return this.getAttribute('disabled')?.toLowerCase() === 'true'; }
  get merchantFeeForPaymentPlan() { return this.getAttribute('merchantFeeForPaymentPlan'); }
  get lineItems() { return this.getAttribute('lineItems'); }
  get forceIFrame() { return this.getAttribute('forceIFrame')?.toLowerCase() === 'true'; }
  get checkoutFlow() { return this.getAttribute('checkoutFlow'); }
  get hideOverlay() { return this.getAttribute('hideOverlay')?.toLowerCase() === 'true'; }
  get virtualEmbeddedIntegration() { return this.getAttribute('virtualEmbeddedIntegration')?.toLowerCase() === 'true'; }
  get returnToMerchant() { return this.getAttribute('returnToMerchant')?.toLowerCase() === 'true'; }
  get testCardDetails() { return this.getAttribute('testCardDetails'); }
  get tokenizerSubdomain() { return this.getAttribute('tokenizerSubdomain'); }
  get customerCountry() { return this.getAttribute('customerCountry'); }
  get allowedShippingLocations() { return this.getAttribute('allowedShippingLocations'); }
  get deniedShippingLocations() { return this.getAttribute('deniedShippingLocations'); }
  get deniedPOBoxShippingLocations() { return this.getAttribute('deniedPOBoxShippingLocations'); }
  get test() { return this.getAttribute('test')?.toLowerCase() === 'true'; }
  get buttonColor() { return this.getAttribute('buttonColor')?.toLowerCase() || ''; }
  get integrationType() { return this.getAttribute('integrationType')?.toLowerCase() || ''; }
  get customerLoggedIn() { return this.getAttribute('customerLoggedIn')?.toLowerCase(); }
  get customerIsMember() { return this.getAttribute('customerIsMember')?.toLowerCase(); }
  get merchantRiskAssessment() { return this.getAttribute('merchantRiskAssessment')?.toLowerCase(); }
  get sameDevice() { return this.getAttribute('sameDevice')?.toLowerCase(); }
  get fulfillmentMethod() { return this.getAttribute('fulfillmentMethod')?.toLowerCase(); }
  get accountCreationDate() { return this.getAttribute('accountCreationDate')?.toLowerCase(); }
  get lastTransactionDate() { return this.getAttribute('lastTransactionDate')?.toLowerCase(); }
  get customerTransactionCount() { return this.getAttribute('customerTransactionCount')?.toLowerCase(); }
  get customerAverageOrderValue() { return this.getAttribute('customerAverageOrderValue')?.toLowerCase(); }
  get customerFraudIncidents() { return this.getAttribute('customerFraudIncidents')?.toLowerCase(); }
  get entrySource() { return this.getAttribute('entrySource'); }

  // Show or hide different button verbiage based on html attribute
  private handleButtonVerbiage() {
    if (this.buttonVerbiage === 'pay-later') {
      this.shadow?.getElementById('zip-button-text-default').classList.add('hidden');
      this.shadow?.getElementById('zip-button-text-pay-later').classList.remove('hidden');
    } else {
      this.shadow?.getElementById('zip-button-text-default').classList.remove('hidden');
      this.shadow?.getElementById('zip-button-text-pay-later').classList.add('hidden');
    }
  }

  private applyButtonVisibility(state: string = '') {
    const checkoutButton = this.shadow.querySelector('.checkout-button');
    (checkoutButton as any).style.visibility = state;
  }

  private applyButtonBackgroundColor(color: string) {
    const checkoutButton = this.shadow.querySelector('.checkout-button');
    (checkoutButton as any).style.backgroundColor = color;
  }

  private applyButtonGrayLabel(logoUrl: string = '') {
    const checkoutButton = this.shadow.querySelector('.checkout-button');
    let imageTag: string = '';
    if (logoUrl) {
      imageTag = `<img class="checkout-gray-label-merchant-logo" src="${logoUrl}">`;
    }
    (checkoutButton as any).innerHTML = `<div class="checkout-gray-label">${imageTag}<div class="checkout-gray-label-divider"></div>
    <span class="checkout-gray-label-text" data-i18n="checkoutButton.powered-by">powered by</span><div class="checkout-gray-label-zip-logo">${grayLabelZipLogo}</div></div>`;
  }

  // Updates the configuration environment based upon the gateway URL override.
  private updateConfiguration() {
    if (this.gatewayURLOverride) {
      const overrideConfiguration = getConfigurationForGatewayDomainOverride(this.gatewayURLOverride);
      if (overrideConfiguration) {
        this.configuration = overrideConfiguration;
        virtualCheckout.updateEnvironment(overrideConfiguration.environmentName);
      } else {
        logger.error(`Unable to update environment for gateway URL ${this.gatewayURLOverride}`);
      }
    }
  }

  /**
   * Parse lineItems to LineItem[] type or undefined
   * @param lineItemsJson The checkout line items encoded as a JSON string
   */
  private parseLineItems(lineItemsJson: any): LineItem[] | undefined {
    try {
      const parsedLineItems = JSON.parse(lineItemsJson);
      // If parsed value is not an array, does not match
      if (!Array.isArray(parsedLineItems)) return;

      const correctlyFormatted = (parsedLineItems.every(elem => {
        // If every element of array is not an object, does not match
        // If any key in any object is not part of the LineItem type, does not match
        // nb [] will result in undefined LineItems
        return typeof elem === 'object' && Object.keys(elem).every(key => ['name', 'description', 'quantity', 'price', 'sku', 'isPreOrder', 'releaseDate'].includes(key));
      }));
      if (correctlyFormatted) {
        // Replace all releaseDates with Date value or undefined
        parsedLineItems.forEach(lineItem => {
          // Attempt to construct releaseDate value
          const date = new Date(lineItem.releaseDate);
          if (isNaN(date.valueOf())) {
            logger.warn('LineItems releaseDate not formatted correctly. Please see our docs https://docs.quadpay.com/docs/virtual-checkout#lineitem');

            lineItem.releaseDate = undefined;
          } else {
            lineItem.releaseDate = date;
          }

          // Trim the descriptions to max 100 chars
          if (lineItem.description) lineItem.description = lineItem.description.substring(0, 100);
        });
        // Finally return parsed lineItems
        return parsedLineItems;
      }
      else {
        logger.warn('LineItems not formatted correctly. Please see our docs https://docs.quadpay.com/docs/virtual-checkout#lineitem');
      }
    } catch (e) {
      logger.warn('LineItems not formatted correctly. Please see our docs https://docs.quadpay.com/docs/virtual-checkout#lineitem');
    }
  }

  // Parse testCardDetails to Card type or undefined
  private parseTestCardDetails(cardDetails?: string): Card | undefined {
    // If no cardDetails passed in, grab testCardDetails from button attribute
    if (!cardDetails) cardDetails = this.testCardDetails;
    try {
      const parsed = JSON.parse(cardDetails);
      // If parsed value is not an object, return
      if (typeof parsed !== 'object') {
        return;
      }
      return parsed as Card;
    } catch (e) {
      logger.warn('TestCardDetails not formatted correctly. Please enter a JSON.stringified() object');
    }
  }

  /**
   * @param numberAsString The number value as a string.
   * @returns The number value as a number.
   */
  private stringToNumber(numberAsString: any): number {
    const isEmptyString = ((numberAsString instanceof String || typeof numberAsString === 'string') && numberAsString?.trim() === '');

    return (numberAsString == null ||
      isEmptyString ||
      isNaN(Number(numberAsString))
      ? NaN
      : parseFloat(numberAsString));
  }

  // Constructs an order object based on the current attributes.
  private buildOrder(): Order {
    const lineItems = this.parseLineItems(this.lineItems);

    return {
      amount: this.stringToNumber(this.amount),
      currency: this.currency,
      merchantReference: this.merchantReference,
      shippingAmount: this.stringToNumber(this.shippingAmount),
      taxAmount: this.stringToNumber(this.taxAmount),
      merchantFeeForPaymentPlan: this.stringToNumber(this.merchantFeeForPaymentPlan),
      lineItems,
      customer: {
        address1: this.customerAddressLine1,
        address2: this.customerAddressLine2,
        city: this.customerCity,
        country: this.customerCountry,
        email: this.customerEmail,
        firstName: this.customerFirstName,
        lastName: this.customerLastName,
        phoneNumber: this.customerPhoneNumber,
        postalCode: this.customerPostalCode,
        state: this.customerState,
      },
    };
  }

  // Launches the virtual checkout
  private async openCheckout(event?: Event) {
    if (this.isDisabled) {
      return;
    }

    // Stop VC open checkout if it's not valid. This improves classic-api integration
    const errors = virtualCheckout.validate(this.merchantId, this.buildOrder());
    if (errors.length > 0) {
      logger.info('Virtual Checkout was unable to open, if you are using the classic-api integration, add the integrationType="api"');
      return;
    }

    this.updateConfiguration();
    let checkoutFlow;
    let shippingPreference: ShippingPreference;

    if (this.checkoutFlow === CheckoutFlow.express.toString()) {
      checkoutFlow = CheckoutFlow.express;
      shippingPreference = getShippingPreference(
        this.allowedShippingLocations,
        this.deniedShippingLocations,
        this.deniedPOBoxShippingLocations,
      );
    } else if (this.checkoutFlow === CheckoutFlow.fiserv.toString()) {
      checkoutFlow = CheckoutFlow.fiserv;
    } else if (this.checkoutFlow === CheckoutFlow.cardSecure.toString()) {
      checkoutFlow = CheckoutFlow.cardSecure;
    } else if (this.checkoutFlow === CheckoutFlow.braintree.toString()) {
      checkoutFlow = CheckoutFlow.braintree;
    } else {
      checkoutFlow = CheckoutFlow.standard;
    }

    // Check if entry source is set, if not, default to "zipbutton"
    const entrySource = EntrySource[this.entrySource] || EntrySource.ZipButton;

    const configuration: CheckoutConfiguration = {
      checkoutFlow,
      entrySource,
      forceIframe: this.forceIFrame,
      hideOverlay: this.hideOverlay,
      virtualEmbeddedIntegration: this.virtualEmbeddedIntegration,
      returnToMerchant: this.returnToMerchant,
      tokenizerSubdomain: this.tokenizerSubdomain,
      shippingPreference: shippingPreference,
      test: this.test,
      additionalRiskData: {
        customerLoggedIn: this.customerLoggedIn,
        customerIsMember: this.customerIsMember,
        merchantRiskAssessment: this.merchantRiskAssessment,
        sameDevice: this.sameDevice,
        fulfillmentMethod: this.fulfillmentMethod,
        accountCreationDate: this.accountCreationDate,
        lastTransactionDate: this.lastTransactionDate,
        customerTransactionCount: this.customerTransactionCount,
        customerAverageOrderValue: this.customerAverageOrderValue,
        customerFraudIncidents: this.customerFraudIncidents,
      },
    };

    await virtualCheckout.openCheckout(this.merchantId, this.buildOrder(), configuration);
  }

  // Sets the test card details if the merchant has a valid test card and this is a test environment
  setCardDetails(cardDetails?: string) {
    const parsedCardDetails = this.parseTestCardDetails(cardDetails);
    if (this.configuration.isTestEnvironment && parsedCardDetails) {
      virtualCheckout.setCardDetails(parsedCardDetails);
    }
  }

  /**
   * Focuses on the already launched virtual checkout
   */
  focusCheckout() {
    virtualCheckout.focusCheckout();
  }

  /**
   * Closes the launched virtual checkout
   */
  async closeCheckout() {
    await virtualCheckout.closeCheckout();
  }

  /**
   * Sets the success handler for the virtual checkout
   * @param callback
   */
  onSuccess(callback: (card: Card, cardholder: Cardholder, customer: Customer) => Promise<void>) {
    virtualCheckout.onSuccess(callback);
  }

  /**
   * Sets the success handler for the virtual checkout
   * @param callback
   */
  onComplete(callback: (result?: VirtualSuccessCallback) => Promise<void>) {
    virtualCheckout.onComplete(callback);
  }

  /**
   * DEPRECATED!
   * Unused setter for handling declines from the virtual checkout
   * @param callback
   */
  onDecline(callback) {
    // Unused -- left for backwards compatibility
  }

  /**
   * Sets the close handler for virtual checkout
   * @param callback
   */
  onClose(callback: (message: string) => Promise<void>) {
    virtualCheckout.onClose(callback);
  }

  /**
   * Sets the error handler for virtual checkout
   * @param callback
   */
  onError(callback: (errorMessages: string[]) => Promise<void>) {
    virtualCheckout.onError(callback);
  }
}

// This creates a Quadpay button as a web component that is used to launch and manage the virtual checkout.
export default function loadQuadpayButton() {
  const configuration = getConfiguration();
  if (configuration.killSwitchDomains.some(domain => window.location.hostname.includes(domain))) {
    return;
  };

  // Handle multiple reloads
  if (!customElements.get('quadpay-button')) {
    customElements.define('quadpay-button', class extends QuadPayButton {});
  }

  if (!customElements.get('zip-button')) {
    customElements.define('zip-button', class extends QuadPayButton {});
  }
}