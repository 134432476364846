import optimizely, {
  enums,
  type Client,
  type EventTags,
} from '@optimizely/optimizely-sdk';
import type { ErrorHandler, LogHandler } from '@optimizely/js-sdk-logging';
import getConfiguration, {
  type EnvironmentConfiguration,
} from '@/shared/configuration';
import logger from '@/shared/logger';
import {
  EventBus,
  getEventBus,
  viewedLearnMoreModalEvent,
  viewedPaymentWidgetEvent,
  viewedStandardWidgetEvent,
} from '@/services/events/event-bus';
import {
  type EventModel,
  FeatureFlagKey,
  ScheduleType,
  PaymentScheduleMap,
} from './models';
import { WIDGET_DATA_KEY } from '@/services/storage';
import { Gateway, getGateway } from '../gateway';
import { Fingerprint, getFingerprintAgent } from '@/services/fingerprinting';
import { Options } from '@/services/analytics';
import { BankPartnerDefault, MerchantFeeTierArray } from './index';
import { PaymentSchedule, WidgetDataModel } from '@/services/gateway/models';
import { isEmpty } from '@/utils/isEmpty';

export class WidgetData {
  private readonly configuration: EnvironmentConfiguration;
  private readonly datafileLoadTimeoutInMs: number = 10000;
  private readonly eventBus: EventBus;
  private readonly fingerprintAgent: Fingerprint;
  private readonly gateway: Gateway;
  private readonly OptimizelyDatafileMessagePrefix = 'No datafile specified';

  private optimizelyClientInstance: Client;
  private initialized: boolean = false;
  private merchantId = '';
  private websiteDomain = `${window.location.protocol}//${window.location.hostname}`;
  private widgetData: WidgetDataModel = {};
  private amount: string | undefined;
  private loadedWidgetData: boolean = false;

  constructor() {
    this.configuration = getConfiguration();
    this.eventBus = getEventBus();
    this.fingerprintAgent = getFingerprintAgent();
    this.gateway = getGateway();
    this.subscribeEvents();
  }

  setAmount(amount: string) {
    this.amount = amount;
  }

  setMerchantId(merchantId: string) {
    this.merchantId = merchantId;
  }

  setWebsiteDomain(websiteDomain: string) {
    this.websiteDomain = websiteDomain;
  }

  async initialize() {
    if (this.initialized) return;

    if (this.configuration.useOptimizelyDebugMode) {
      optimizely.setLogLevel('debug');
    }

    const customErrorHandler: ErrorHandler = {
      handleError: (exception: Error) => {
        if (exception.message.includes(this.OptimizelyDatafileMessagePrefix)) {
          logger.warn(
            'Encountered Optimizely config validation oversensitivity, moving on',
          );
        } else {
          logger.error(
            `Handling Optimizely error: ${exception.message}`,
            exception,
          );
        }
      },
    };

    const customLogHandler: LogHandler = {
      log: (level, message) => {
        if (message.includes(this.OptimizelyDatafileMessagePrefix)) return;
        logger.warn(`OPTIMIZELY level: ${level}, message: ${message}`);
      },
    };

    this.optimizelyClientInstance = optimizely.createInstance({
      sdkKey: this.configuration.optimizelySdkKey,
      errorHandler: customErrorHandler,
      logger: customLogHandler,
      // Log levels 3 (warning) and 4 (error) only
      logLevel: enums.LOG_LEVEL.WARNING,
    });

    try {
      const result = await this.optimizelyClientInstance.onReady({
        timeout: this.datafileLoadTimeoutInMs,
      });

      if (result.success) {
        // initalized only if successful
        this.initialized = true;
        logger.info('Optimizely instance is ready!', result.success);
      } else if (result.reason) {
        logger.error(result.reason);
      }
    } catch (e) {
      logger.error('Unable to initialize optimizely', e);
    }
  }

  async load() {
    let shouldRefetchWidgetData = false;
    // Load widget data from cache first
    const widgetDataJson = sessionStorage.getItem(WIDGET_DATA_KEY);
    if (widgetDataJson) {
      try {
        const widgetData = JSON.parse(widgetDataJson);
        this.widgetData = widgetData;

        // If widget is loaded with a different merchant ID than last time
        // Or a different amount than last time, fetch widget data again
        if (
          widgetData.merchantId !== this.merchantId ||
          widgetData.amount !== this.amount
        ) {
          shouldRefetchWidgetData = true;
        }
      } catch (error) {
        logger.error(
          `Failed to parse widget data json: ${widgetDataJson}`,
          error,
        );
      }
    }
    if (this.loadedWidgetData && !shouldRefetchWidgetData) return;
    this.loadedWidgetData = true;

    // Try loading widget data from gateway
    if (isEmpty(this.widgetData) || shouldRefetchWidgetData) {
      const fingerprint = await this.fingerprintAgent.onReady();
      const response = await this.gateway.fetchWidgetData(
        fingerprint,
        this.merchantId,
        this.websiteDomain,
        this.amount,
      );
      if (response) {
        this.widgetData = response;
        this.widgetData.amount = this.amount;

        sessionStorage.setItem(
          WIDGET_DATA_KEY,
          JSON.stringify(this.widgetData),
        );
        this.widgetData = response;
        sessionStorage.setItem(
          WIDGET_DATA_KEY,
          JSON.stringify(this.widgetData),
        );
      } else {
        this.loadedWidgetData = false;
      }
    }
  }

  async fetchPaymentSchedules(amount: string): Promise<PaymentSchedule[]> {
    const response = await this.gateway.fetchPaymentSchedules(this.merchantId, amount);
    if (response.paymentSchedules) {
      this.widgetData.paymentSchedules = response.paymentSchedules;
      this.widgetData.amount = amount; // set amount for caching purposes
    }
    return response.paymentSchedules ?? [];
  }

  getPaymentSchedules(): PaymentSchedule[] {
    return this.widgetData.paymentSchedules ?? [];
  }

  getMappedPaymentSchedules(): PaymentScheduleMap {
    return (this.widgetData.paymentSchedules ?? []).reduce((map, schedule) => {
      if (schedule.installmentCount === 4) {
        map[ScheduleType.payin4] = schedule;
      } else if (schedule.installmentCount === 8) {
        map[ScheduleType.payin8] = schedule;
      }

      return map;
    }, {}) as PaymentScheduleMap;
  }

  isFeatureEnabled(
    featureKey: FeatureFlagKey,
    defaultValue = false,
  ): boolean {
    if (this.widgetData?.featureFlags?.[featureKey]) {
      logger.debug(
        `Optimizely feature key ${featureKey} => ${this.widgetData?.featureFlags?.[featureKey].isEnabled})`,
      );
      return this.widgetData?.featureFlags?.[featureKey].isEnabled;
    }
    return defaultValue;
  }

  /**
   * Checks optimizely instance for an enabled feature's variable to return the value based on variation
   * @param featureKey - A string param for an enabled feature in the Optimizely project
   * @param variableKey - A string param for the enabled feature's variable
   * @param merchantId - Merchant Id to set user attributes
   */
  getFeatureVariableString(
    featureKey: FeatureFlagKey,
    variableKey: string,
    defaultValue = '',
  ) {
    if (this.widgetData?.featureFlags?.[featureKey]?.[variableKey] !== undefined) {
      return this.widgetData?.featureFlags?.[featureKey][variableKey] as string;
    }

    return defaultValue;
  }

  /**
   * Checks optimizely instance for an enabled feature's variable to return the value based on variation
   * @param featureKey - A string param for an enabled feature in the Optimizely project
   * @param variableKey - A string param for the enabled feature's variable
   * @param merchantId - Merchant Id to set user attributes
   */
  getFeatureVariableBoolean(
    featureKey: FeatureFlagKey,
    variableKey: string,
    defaultValue = false,
  ) {
    if (this.widgetData?.featureFlags?.[featureKey]?.[variableKey] !== undefined) {
      return this.widgetData?.featureFlags?.[featureKey][variableKey] as boolean;
    }

    return defaultValue;
  }

  /**
   * Checks optimizely instance for an A/B experiment variation from experiment key
   * @param featureKey - A string param for an enabled feature in the Optimizely project
   * @param experimentKey - A string param for an A/B experiment in the Optimizely project
   * @param defaultValue - Returned value if A/B experiment variation is not returned
   */
  async getABTestVariation(featureKey: string, experimentKey: string, defaultValue = '') {
    await this.initialize();
    const fingerprint = await this.fingerprintAgent.onReady();
    try {
      const userAttributes = {
        ...this.buildUserAttributes(),
      };
      const isEnabled = this.optimizelyClientInstance.isFeatureEnabled(featureKey, fingerprint, userAttributes);
      const variation = this.optimizelyClientInstance.getVariation(experimentKey, fingerprint, userAttributes);
      if (isEnabled) return variation;
      else return defaultValue;
    } catch (e) {
      logger.error(`Failed to get experiment variation from ${experimentKey}. Returning default value of ${defaultValue}`);
      return defaultValue;
    }
  }

  // Widget Promotions events only passed to Optimizely
  async viewedStandardWidgetEvent(widgetId: string, amount: number, url: string) {
    void this.handleEvent('Viewed Standard Widget', { widgetId, amount, url });
  }

  async viewedPromotionWidget(widgetId: string, amount: number, url: string) {
    void this.handleEvent('Viewed Promotion Widget', { widgetId, amount, url });
  }

  async viewedPromotionLearnMoreModal(widgetId: string, amount: number, url: string) {
    void this.handleEvent('Viewed Promotion Learn More Modal', { widgetId, amount, url });
  }

  async viewedWidgetPromoDetails(widgetId: string, amount: number, url: string) {
    void this.handleEvent('Viewed Widget Promo Details', { widgetId, amount, url });
  }

  private buildUserAttributes() {
    // Determine if the user is on iOS for audience filtering
    const isIOS = /ip(ad|hone|od)/i.test(navigator.userAgent);

    const userAttributes = {
      environmentName: this.configuration.environmentName,
      hostedRegion: this.configuration.hostedRegion?.toString(),
      merchantId: this.merchantId,
      domain: window.location.hostname,
      isIOS,
    };

    return userAttributes;
  }

  private subscribeEvents() {
    this.eventBus.subscribeViewedStandardWidgetEvent(
      (
        amount: number,
        domain: string,
        path: string,
        loadingDuration: number,
        options: Options,
        variationText: string,
        widgetId: string,
      ) => {
        void this.handleEvent(viewedStandardWidgetEvent, { amount, widgetId, url: this.websiteDomain, loadingDuration, variationText });
      },
    );

    this.eventBus.subscribeViewedPaymentWidgetEvent(
      (
        amount: number,
        domain: string,
        path: string,
        loadingDuration: number,
        options: Options,
        widgetId: string,
      ) => {
        void this.handleEvent(viewedPaymentWidgetEvent, { amount, widgetId, url: this.websiteDomain, loadingDuration });
      },
    );

    this.eventBus.subscribeViewedLearnMoreModalEvent(
      (
        amount: number,
        domain: string,
        path: string,
        widgetType: string,
        variationText: string,
        widgetId: string,
      ) => {
        void this.handleEvent(viewedLearnMoreModalEvent, { amount, widgetId, url: this.websiteDomain, variationText });
      },
    );
  }

  private async handleEvent(eventName: string, props: EventModel) {
    const fingerprint = await this.fingerprintAgent.onReady();

    if (
      !this.checkOptimizelyInitialization(
        `handling ${eventName} for merchant '${this.merchantId} and anonymous user ${fingerprint}. Widget ${props.widgetId} on page ${props.url}'`,
      )
    ) {
      return;
    }

    const userAttributes = {
      ...this.buildUserAttributes(),
      ...props,
    };

    const amountInCents = Math.floor((props.amount || 0) * 100);
    const tags: EventTags = {
      revenue: amountInCents,
    };

    this.optimizelyClientInstance.track(
      eventName,
      fingerprint,
      userAttributes,
      tags,
    );
  }

  private checkOptimizelyInitialization(contextForErrorMessage: string) {
    if (!this.initialized) {
      const errorMessage = `Attempted to use optimizely client before initialization for ${contextForErrorMessage}`;
      logger.error(errorMessage);

      return false;
    }

    return true;
  }

  getMerchantBankPartner(): string {
    return this.widgetData?.bankPartner || BankPartnerDefault;
  }

  getMerchantFeeTiers(): MerchantFeeTierArray {
    return this.widgetData?.feeTiers || [];
  }

  getMerchantName(): string {
    return this.widgetData?.merchantName || '';
  }
}

let widgetData: WidgetData;

export const getWidgetData = (): WidgetData => {
  if (!widgetData) {
    widgetData = new WidgetData();
  }

  return widgetData;
};
