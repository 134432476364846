import { ApiCheckoutConfiguration, ApiSuccessCallback, LineItem, Order, Card, Cardholder, Customer, CheckoutFlow, VirtualSuccessCallback, CheckoutConfiguration, CheckoutOptions } from '@/sdk-checkout-integration/sdk-integration-models';
import { Installment } from './calculate-installments/installment-model';
import { CalculateMerchantFeeForPaymentPlanRequest, CalculateMerchantFeeForPaymentPlanResponse } from './merchant-fees/models';

/**
 * The exports in this file are used to define the API contract to merchants that consume this library.
 * Anything defined in here is fair game to be used by a merchant and must be support/backwards-compatible
 * for all subsequent major releases.
 */
export const VirtualCheckoutApiName = 'virtualCheckout';
export interface VirtualCheckoutApi {
  openCheckout: (merchantId: string, order: Order, checkoutFlowOrConfiguration?: CheckoutFlow | CheckoutConfiguration, forceIframe?: boolean, hideOverlay?: boolean) => Promise<void>
  setCardDetails: (testCard) => void
  onSuccess: (callback: (card: Card, cardholder: Cardholder, customer: Customer) => Promise<void>) => void
  onComplete: (callback: (result: VirtualSuccessCallback) => Promise<void>) => void
  onError: (callback: (errorMessages: string[]) => Promise<void>) => void
  onClose: (callback: (message: string) => Promise<void>) => void
  focusCheckout: () => void
  closeCheckout: () => Promise<void>
  updateEnvironment: (environmentName: string) => void
  validate: (merchantId: string, order: Order, configuration?: CheckoutConfiguration) => string[]
  getCompleteResult: () => VirtualSuccessCallback
  saveResult: (result: VirtualSuccessCallback) => void
  clearResult: () => void
}

/**
 * The exports in this file are used to define the API contract to merchants that consume this library.
 * Anything defined in here is fair game to be used by a merchant and must be support/backwards-compatible
 * for all subsequent major releases.
 */
export const ApiCheckoutApiName = 'apiCheckout';
export interface ApiCheckoutApi {
  buildGatewayUrl: (checkoutOptions: CheckoutOptions, signature: string) => URL
  openCheckout: (checkoutOptions: CheckoutOptions, signature: string, configuration?: ApiCheckoutConfiguration) => Promise<void>
  onSuccess: (callback: (result: ApiSuccessCallback) => Promise<void>) => void
  onComplete: (callback: (result: ApiSuccessCallback) => Promise<void>) => void
  onError: (callback: (errorMessages: string[]) => Promise<void>) => void
  onClose: (callback: (message: string) => Promise<void>) => void
  focusCheckout: () => void
  closeCheckout: () => Promise<void>
  updateEnvironment: (environmentName: string) => void
  validate: (merchantId: string, order: Order, merchantReference: string, signature: string, configuration?: CheckoutConfiguration) => string[]
  getCompleteResult: () => ApiSuccessCallback
  saveResult: (result: VirtualSuccessCallback) => void
  clearResult: () => void
}

// These types are when we are defining functions directly.  This is arguably an anti pattern because it's near impossible to add similar
// functionality to this context, so the interface-driven approach above should be used going forward.
export const AssociateApiName = 'associate';
export type AssociateApi = (merchantReference: string, merchantPlatformId: string, lineItems?: LineItem[], environmentName?: string) => Promise<void>;

export const CalculateInstallmentsApiName = 'calculateInstallments';
export type CalculateInstallmentsApi = (orderAmount: number) => Installment[];

export const WidgetApiName = 'widget';
export interface WidgetApi {
  displayModal: (widgetId: string) => void
  hideModal: (widgetId: string) => void
  updateEnvironment: (environmentName: string) => void
  getWidgetIds: () => void
  getWidgetVerbiage: (attribute: string, isServiceFeeMerchant: boolean, isMFPPMerchant: boolean, hasFees: boolean) => string
  getPromotionsVariation: () => Promise<string>
}

export const MerchantFeesApiName = 'merchantFees';
export interface MerchantFeesApi {
  calculateMerchantFeeForPaymentPlan: (request: CalculateMerchantFeeForPaymentPlanRequest, environmentName?: string) => Promise<CalculateMerchantFeeForPaymentPlanResponse>
}

export const PaymentMethodsApiName = 'paymentMethods';
export interface PaymentMethodsApi {
  displayModal: (widgetId: string) => void
}

export const SharedApiName = 'shared';
export interface SharedApi {
  parseFullAmount: (amount: string | number) => number
  calcInstallmentAmount: (amount: string, installmentFee: string | number, isServiceFeeMerchant: boolean) => string
  calcInstallmentAmounts: (amount: string, installmentFee: string | number, isServiceFeeMerchant: boolean) => string[]
}