import getConfiguration, { EnvironmentConfiguration } from '@/shared/configuration';
import { getFingerprintAgent } from '@/services/fingerprinting';
import { getWidgetData } from '@/services/widget-data';
import { getAnalytics } from '@/services/analytics';
import logger from '@/shared/logger';
import { getEventBus } from '@/services/events';

const initialise = async (configuration: EnvironmentConfiguration) => {
  const promises: Array<Promise<any>> = [];
  const eventBus = getEventBus();

  if (configuration.isWidgetDataFetchingEnabled) {
    const widgetData = getWidgetData();
    promises.push(widgetData.initialize());
    logger.info(
      `Loaded Widget Data configuration for environment ${configuration.environmentName}`,
    );
  }

  if (configuration.isAnalyticsEnabled) {
    const analytics = getAnalytics();
    promises.push(analytics.initialise());
    logger.info(
      `Loaded analytics configuration for environment ${configuration.environmentName}`,
    );
  }

  void Promise.all(promises).then(() => {
    eventBus.initialise();
  });
};

export default async function load() {
  const configuration = getConfiguration();
  if (configuration.isWidgetDataFetchingEnabled || configuration.isAnalyticsEnabled || configuration.experimentsForceEvents) {
    const fingerprintAgent = getFingerprintAgent();

    await fingerprintAgent.initialise();
    // eslint-disable-next-line @typescript-eslint/return-await
    return initialise(configuration);
  }
};

export async function loadAnalytics() {
  const configuration = getConfiguration();
  if (configuration.isAnalyticsEnabled) {
    const fingerprintAgent = getFingerprintAgent();
    await fingerprintAgent.initialise();

    const eventBus = getEventBus();
    eventBus.initialise();

    const analytics = getAnalytics();
    await analytics.initialise();
  }
}
