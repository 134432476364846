import { isMobile } from '@/utils/isMobile';
import FingerprintJS, {
  Agent,
  GetResult,
  UnknownComponents,
} from '@fingerprintjs/fingerprintjs';
import { Gateway, getGateway } from '@/services/gateway';
import { FINGERPRINT_KEY } from '@/services/storage';
import { IdentifierResult } from '../gateway/models';
import logger from '@/shared/logger';
import { v4 as uuid } from 'uuid';

export class Fingerprint {
  private readonly gateway: Gateway;
  private readonly readyPromise: Promise<string>;
  private fp: Agent;
  private isInitialised = false;

  private readyPromiseResolver: (
    value: string | PromiseLike<string>
  ) => void;

  constructor() {
    this.gateway = getGateway();
    this.readyPromise = new Promise((resolve) => {
      this.readyPromiseResolver = resolve;
    });
  }

  async onReady() {
    return await this.readyPromise;
  }

  async initialise(): Promise<void> {
    if (this.isInitialised) return;

    // We don't want our fingerprint to live too long as it will need to match with the fingerprint
    // generated in checkout.  This should be the same per session but longer will give more chance
    // of a mismatch hence use session instead of local storage
    const fingerprint = sessionStorage.getItem(FINGERPRINT_KEY);
    if (fingerprint) {
      this.readyPromiseResolver(fingerprint);
      return;
    }

    if (!this.fp) {
      this.fp = await FingerprintJS.load({
        // Stop usage statistics
        // https://github.com/fingerprintjs/fingerprintjs/blob/master/docs/api.md
        monitoring: false,
      });
    }

    let result: GetResult;
    try {
      result = await this.fp.get();
    } catch (error) {
      logger.error('Failed to generate fingerprint', error);
      this.readyPromiseResolver(uuid());
      return;
    }

    let serverFingerprint: IdentifierResult;
    try {
      serverFingerprint = await this.gateway.getIdentifier();
    } catch (error) {
      logger.error('Failed to fetch server fingerprint', error);
    }

    // screenFrame, colorGamut and screenResolution will change fingerpinrt per desktop screen if
    // they are different (e.g. laptop with monitor attached)
    const {
      screenFrame,
      colorGamut,
      screenResolution,
      ...components
    } = result.components;
    let hashComponents: UnknownComponents = (components as unknown) as UnknownComponents;

    // Canvas text entropy is currently not stable
    // https://github.com/fingerprintjs/fingerprintjs/blob/master/docs/extending.md
    if (components.canvas.value) {
      components.canvas.value.text = '';
    }

    // Desktops with multiple screens can compute different fingerprints per screen,
    // but mobiles typically have one screen hence should not cause the fingerprint to change
    if (isMobile()) {
      hashComponents = {
        ...hashComponents,
        screenFrame,
        colorGamut,
        screenResolution,
      };
    }

    // Generate fingerprint with specified list of components instead of all of them
    const clientFingerprint = FingerprintJS.hashComponents(hashComponents);

    const computedFingerprint = serverFingerprint?.identifier
      ? `${clientFingerprint}-${serverFingerprint.identifier}`
      : clientFingerprint;
    // We don't want our fingerprint to live too long as it will need to match with the fingerprint
    // generated in checkout.  This should be the same per session but longer will give more chance
    // of a mismatch hence use session instead of local storage
    sessionStorage.setItem(FINGERPRINT_KEY, computedFingerprint);
    this.readyPromiseResolver(computedFingerprint);

    this.isInitialised = true;
  }
}

let fingerprintImpl: Fingerprint;

export const getFingerprintAgent = (): Fingerprint => {
  if (!fingerprintImpl) {
    fingerprintImpl = new Fingerprint();
  }
  return fingerprintImpl;
};
