import Cookies from 'js-cookie';

/**
 * This export is used to define the configuration value types to remove string parsing everywhere.
 */

import { HostedRegion } from './hosted-regions';

export interface EnvironmentConfiguration {
  // Name of the environment the configuration is for
  environmentName: 'Local' | 'Development' | 'Staging' | 'Sandbox' | 'Production' | 'Unit Test' | 'Development-GB' | 'Sandbox-GB' | 'Production-GB' | 'Development-CA' | 'Sandbox-CA' | 'Production-CA'

  // Indicates if environment is a test environment (true unless production)
  isTestEnvironment: boolean

  // Indicates whether to log to the console
  enableAppLog: boolean

  // Whether or not the widget should be live
  addWidget: boolean

  // Local Storage Key for Widget Verification
  verifyWidgetKey: string

  // The host of the gateway service (e.g. https://gateway.quadpay.com)
  gatewayHost: string

  // The host of the analytics servie (e.g. https://???.com)
  analyticsHost: string

  // Optional parameter that signfies the CDN host of the quadpay.js script (e.g. https://cdn.quadpay.com)
  scriptHost?: string[]

  // Indicates whether to log the issued card details to AI
  logCardToAi: boolean

  // Supported service domains for QP that can be invoked
  serviceDomains: string[]

  // The base path to the secure CDE service
  secureApiBasePath: string

  // The base path to the CDN
  cdnBasePath: string

  // Datadog configuration
  datadogServiceName: string
  datadogApplicationId: string
  datadogClientToken: string
  datadogDatacenter: string
  brand: 'quadpay' | 'zip'

  // Return default or custom widget tag name
  tagname: string
  paymentMethodsTagName: string

  useFiservTestCard: boolean
  fiservApiBasePath: string
  cardSecureApiBasePath: string

  // List of domains that dont send events
  eventsTurnedOffDomains: string[]

  // Analytics configuration
  isAnalyticsEnabled: boolean
  experimentsForceEvents: boolean

  // Optimizely configuration
  isFeatureFlagsEnabled: boolean
  isWidgetDataFetchingEnabled: boolean
  optimizelySdkKey: string
  useOptimizelyDebugMode: boolean
  hostedRegion: HostedRegion

  // List of merchants Ids that will show the mfpp text
  merchantsWithPaymentMFPPText: string[]

  // Contentful configuration
  contentfulSpaceId: string
  contentfulAccessToken: string

  // KillSwitch
  killSwitchMid: string[]
  killSwitchDomains: string []
}

const defaultEnvironment = 'Development';
const scriptName = 'quadpay.js';

const baseConfiguration: EnvironmentConfiguration = {
  environmentName: 'Local',
  isTestEnvironment: true,
  enableAppLog: true,
  addWidget: true,
  verifyWidgetKey: 'QuadPayHasCalledVerify',
  gatewayHost: 'https://localhost:5001',
  analyticsHost: 'https://localhost:1234',
  scriptHost: ['https://localhost:1234'],
  logCardToAi: true,
  serviceDomains: ['quadpay.com', 'quadpay.xyz', 'quadpay.ca', 'zip.co'],
  eventsTurnedOffDomains: ['clekinc.ca'],
  secureApiBasePath: 'https://secure.dev.us.zip.co',
  cdnBasePath: 'https://qp-merchant-configs-dev.azureedge.net',
  datadogApplicationId: 'a4a109f1-8887-4547-bc30-b8d5a276a2d2',
  datadogServiceName: 'zip.js',
  datadogClientToken: 'pub4dada2b5b2f800073d4270b75ee65804',
  datadogDatacenter: 'us',
  brand: 'zip',
  tagname: 'quadpay-widget-v3',
  paymentMethodsTagName: 'zip-payment-widget',
  useFiservTestCard: true,
  fiservApiBasePath: 'https://cert.api.firstdata.com/paymentjs/v2',
  cardSecureApiBasePath: 'cardconnect.com/cardsecure/api/v1/ccn',
  isAnalyticsEnabled: true,
  isFeatureFlagsEnabled: true,
  isWidgetDataFetchingEnabled: true,
  optimizelySdkKey: 'CF1CHhZ3jebMaLfVHVxvm',
  useOptimizelyDebugMode: true,
  hostedRegion: HostedRegion.US,
  merchantsWithPaymentMFPPText: ['a77c291d-fec0-4b04-9daf-c165f5be8313', '3af3942c-a878-43bd-a113-9c64a29d05ed', '9f7c8dcc-a546-45e4-a789-b65055abe0db'],
  experimentsForceEvents: true,
  contentfulSpaceId: 'l6e5nwwfjxup',
  contentfulAccessToken: 'zGswzJyADdzV6-7gSKIdzn0RJou_h9PphwY1P0AP_Q4',
  killSwitchMid: ['a77c291d-fec0-4b04-9daf-c165f5be8313'],
  killSwitchDomains: ['bedbathandbeyond', 'harmonfacevalues', 'buybuybaby'],
};

const environmentConfigurations: EnvironmentConfiguration[] = new Array<EnvironmentConfiguration>(
  {
    ...baseConfiguration,
  },
  {
    ...baseConfiguration,
    environmentName: 'Development',
    gatewayHost: 'https://gateway.dev.us.zip.co',
    analyticsHost: 'https://master.branch.quadpay.xyz/analytics',
    scriptHost: ['https://cdn.dev.us.zip.co', 'https://cdn-development.quadpay.xyz'],
    brand: 'zip',
  },
  {
    ...baseConfiguration,
    environmentName: 'Staging',
    enableAppLog: false,
    secureApiBasePath: 'https://dev.secure.quadpay.xyz',
    gatewayHost: 'https://gateway.stg.us.quadpay.xyz',
    analyticsHost: 'https://master.branch.quadpay.xyz/analytics',
    scriptHost: ['https://cdn.stg.us.zip.co', 'https://cdn-stg.quadpay.com'],
    cdnBasePath: 'https://qp-merchant-configs-stg.azureedge.net',
    brand: 'zip',
  },
  {
    ...baseConfiguration,
    environmentName: 'Sandbox',
    enableAppLog: false,
    addWidget: false,
    gatewayHost: 'https://gateway.sand.us.zip.co',
    analyticsHost: 'https://sandbox.api.quadpay.com/analytics',
    scriptHost: ['https://cdn.sand.us.zip.co', 'https://cdn-sandbox.quadpay.com'],
    cdnBasePath: 'https://qp-merchant-configs-sbx.azureedge.net',
    brand: 'zip',
    optimizelySdkKey: 'bX5vJvA78gXDqU99LKYPe',
  },
  {
    ...baseConfiguration,
    environmentName: 'Production',
    isTestEnvironment: false,
    enableAppLog: false,
    addWidget: false,
    gatewayHost: 'https://gateway.us.zip.co',
    analyticsHost: 'https://api.quadpay.com/analytics',
    scriptHost: ['https://cdn.quadpay.com', 'https://cdn.us.zip.co'],
    logCardToAi: false,
    cdnBasePath: 'https://qp-merchant-configs-prd.azureedge.net',
    secureApiBasePath: 'https://secure.us.zip.co',
    useFiservTestCard: false,
    fiservApiBasePath: 'https://prod.api.firstdata.com/paymentjs/v2',
    optimizelySdkKey: 'PEUZTmhmmw72wsxQWEFCB',
    useOptimizelyDebugMode: false,
  },
  {
    ...baseConfiguration,
    environmentName: 'Unit Test',
    enableAppLog: false,
    gatewayHost: 'https://localhost-test',
    analyticsHost: 'https://localhost-test',
    scriptHost: ['https://localhost-test'],
  },
  {
    ...baseConfiguration,
    environmentName: 'Development-CA',
    serviceDomains: ['quadpay.ca'],
    gatewayHost: 'https://master.gateway.quadpay.ca',
    analyticsHost: 'https://master.branch.quadpay.xyz/analytics',
    scriptHost: ['https://cdn-development.quadpay.ca', 'https://cdn.dev.ca.zip.co'],
  },
  {
    ...baseConfiguration,
    environmentName: 'Sandbox-CA',
    serviceDomains: ['quadpay.ca'],
    enableAppLog: false,
    addWidget: false,
    gatewayHost: 'https://sandbox.gateway.quadpay.ca',
    analyticsHost: 'https://sandbox.api.quadpay.com/analytics',
    scriptHost: ['https://cdn-sandbox.quadpay.ca', 'https://cdn.sand.ca.zip.co'],
    cdnBasePath: 'https://qp-merchant-configs-sbx.azureedge.net',
    optimizelySdkKey: 'bX5vJvA78gXDqU99LKYPe',
  },
  {
    ...baseConfiguration,
    environmentName: 'Production-CA',
    isTestEnvironment: false,
    serviceDomains: ['quadpay.ca'],
    enableAppLog: false,
    addWidget: false,
    gatewayHost: 'https://gateway.quadpay.ca',
    analyticsHost: 'https://api.quadpay.com/analytics',
    scriptHost: ['https://cdn.quadpay.ca', 'https://cdn.ca.zip.co'],
    logCardToAi: false,
    cdnBasePath: 'https://qp-merchant-configs-prd.azureedge.net',
    secureApiBasePath: 'https://secure.us.zip.co',
    useFiservTestCard: false,
    fiservApiBasePath: 'https://prod.api.firstdata.com/paymentjs/v2',
    optimizelySdkKey: 'PEUZTmhmmw72wsxQWEFCB',
    useOptimizelyDebugMode: false,
  },
  {
    ...baseConfiguration,
    environmentName: 'Development-GB',
    gatewayHost: 'https://master.gateway.zipco.xyz',
    analyticsHost: 'https://master.branch.quadpay.xyz/analytics',
    scriptHost: ['https://cdn-development.zipco.xyz'],
    brand: 'zip',
  },
  {
    ...baseConfiguration,
    environmentName: 'Sandbox-GB',
    enableAppLog: false,
    gatewayHost: 'https://gateway.sand.gb.zip.co',
    analyticsHost: 'https://sandbox.api.quadpay.com/analytics',
    scriptHost: ['https://cdn.sand.gb.zip.co'],
    brand: 'zip',
    optimizelySdkKey: 'bX5vJvA78gXDqU99LKYPe',
  },
  {
    ...baseConfiguration,
    environmentName: 'Production-GB',
    isTestEnvironment: false,
    enableAppLog: false,
    gatewayHost: 'https://gateway.gb.zip.co',
    analyticsHost: 'https://api.quadpay.com/analytics',
    scriptHost: ['https://cdn.gb.zip.co'],
    logCardToAi: false,
    secureApiBasePath: 'https://secure.quadpay.com',
    brand: 'zip',
    useFiservTestCard: false,
    fiservApiBasePath: 'https://prod.api.firstdata.com/paymentjs/v2',
    optimizelySdkKey: 'PEUZTmhmmw72wsxQWEFCB',
    useOptimizelyDebugMode: false,
  },
);

/**
 * Gets the currently executing script that is loading quadpay.js.
 *
 * Inspired by: https://stackoverflow.com/questions/403967/how-may-i-reference-the-script-tag-that-loaded-the-currently-executing-script
 */
function getCurrentScript(): any {
  // All non-IE browsers support this
  if (document.currentScript) {
    return document.currentScript;
  }

  // Search for quadpay.js script
  const allScripts = document.getElementsByTagName('script');
  for (const script of Array.from(allScripts)) {
    if (script?.src?.toLowerCase().includes(scriptName)) {
      return script;
    }
  }

  // Final fallback to get the executing script in case something isn't found under quadpay.js
  return allScripts[allScripts.length - 1];
}

let currentEnvironmentConfiguration = getConfigurationForEnvironmentName(defaultEnvironment);

// Determine which URL loaded this script and set the environment based on that.
const currentScript = getCurrentScript();
if (process.env.NODE_ENV === 'development' &&
  !!process.env.QA_ENV &&
  Cookies.get('zip-qa')) {
  // QA configuration pulled from zip-qa cookie
  currentEnvironmentConfiguration = getConfigurationFromQaCookie(Cookies.get('zip-qa'));
} else if (process.env.NODE_ENV !== 'test' && currentScript?.src) {
  const quadpayJsSrcUrl = new URL(currentScript.src);
  const quadpayJsHost = `${quadpayJsSrcUrl.protocol}//${quadpayJsSrcUrl.hostname}`;
  const foundEnvironment = environmentConfigurations.find((value: EnvironmentConfiguration) => value.scriptHost.map(m => m.toLowerCase()).includes(quadpayJsHost.toLowerCase()));
  if (foundEnvironment) {
    currentEnvironmentConfiguration = foundEnvironment;

    if (quadpayJsSrcUrl.searchParams?.get('debug') === 'true') {
      currentEnvironmentConfiguration.enableAppLog = true;
    }

    // Determine if tagname query param was passed and set widget tag name based on value
    if (quadpayJsSrcUrl.searchParams?.get('tagname') !== null) {
      currentEnvironmentConfiguration.tagname = quadpayJsSrcUrl.searchParams?.get('tagname');
    }

    if (quadpayJsSrcUrl.searchParams?.get('paymentMethodsTagName') !== null) {
      currentEnvironmentConfiguration.paymentMethodsTagName = quadpayJsSrcUrl.searchParams?.get('paymentMethodsTagName');
    }
  }
} else if (process.env.NODE_ENV === 'test') {
  // Unit test environment
  currentEnvironmentConfiguration = environmentConfigurations.find((value: EnvironmentConfiguration) => value.environmentName === 'Unit Test');
}

function getConfigurationFromQaCookie(cookieValue: string): EnvironmentConfiguration {
  const parts = cookieValue.split('-');
  if (parts.length !== 2) {
    return getConfigurationForEnvironmentName(defaultEnvironment);
  }

  const [env, region] = parts;
  // US configurations don't contain 'US' in their name, so just use the environment
  const environmentName = region.toLowerCase() === 'us' ? env : `${env}-${region}`;
  return getConfigurationForEnvironmentName(environmentName);
}

export function getConfigurationForEnvironmentName(environmentName: string): EnvironmentConfiguration {
  const foundConfiguration = environmentConfigurations.find((value: EnvironmentConfiguration) => value.environmentName.toLowerCase() === environmentName.toLowerCase());
  return Object.freeze(foundConfiguration);
}

export function getConfigurationForGatewayDomainOverride(gatewayDomainOverride: string) {
  const foundConfiguration = environmentConfigurations.find((value: EnvironmentConfiguration) => {
    const domain = value.gatewayHost.substring('https://'.length);
    return domain.toLowerCase() === gatewayDomainOverride.toLowerCase();
  });

  return Object.freeze(foundConfiguration);
}

export default function getConfiguration(): EnvironmentConfiguration {
  return Object.freeze(currentEnvironmentConfiguration);
}
