import { supportsPopups } from 'belter';
import getConfiguration, { EnvironmentConfiguration, getConfigurationForEnvironmentName } from '../../shared/configuration';
import { ApiCheckoutConfiguration, Order, ApiSuccessCallback, CheckoutOptions, EntrySource } from '@/sdk-checkout-integration/sdk-integration-models';
import zoidComponentInit from '../zoid-component';
import logger from '../../shared/logger';
import { ApiCheckoutApi } from '../../api-definitions';
import { ENTRY_SOURCE_KEY } from '@/services/storage';

// This must match what's in package.json!  This is to ensure that the gateway loads the same (read compatible) version of post robot for the IE bridge.
const postRobotVersion = '10.0.44';

// Used for UUID validation with merchant IDs
const uuidRegex = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[489ab][0-9a-f]{3}-[0-9a-f]{12}$/i);

let environmentConfiguration: EnvironmentConfiguration = getConfiguration();
logger.info(`Successfully instantiated the api checkout API in environment ${environmentConfiguration.environmentName}`);

// Default unhappy path callbacks to empty methods so they are always defined and easier to prevent errors
let onSuccessCallback: (result: ApiSuccessCallback) => Promise<void> = async () => {};
let onCompleteCallback: (result: ApiSuccessCallback) => Promise<void> = async () => {};
let onCloseCallback: (message: string) => Promise<void> = async () => {};
let onErrorCallback: (errorMessages: string[]) => Promise<void> = async () => {};

let zoidComponent: any;

// Result from zoid callback key
const callbackResult = 'qp-vc-result';

/**
 * Constructs the gateway redirect URL to authorize a virtual checkout order.
 * @param merchantId
 * @param order
 */
export function buildGatewayUrl(checkoutOptions: CheckoutOptions, signature: string): URL {
  // assign authorize path
  const checkoutPath = 'orders/authorize-popup-checkout-redirect';

  const url = new URL(`${environmentConfiguration.gatewayHost}/${checkoutPath}`);

  // Append entry source to request body based on sessionstorage value
  // If no value, assume merchant used their own custom button and opened checkout through a custom button
  const entrySource = window.sessionStorage.getItem(ENTRY_SOURCE_KEY) || EntrySource.MerchantButton;
  logger.debug('entry source', entrySource);

  url.searchParams.append('RequestBody', JSON.stringify(checkoutOptions));
  url.searchParams.append('Signature', signature);
  url.searchParams.append('EntrySource', entrySource);

  return url;
}

/**
 * Handle a successful checkout callback message through Zoid as passed from the gateway containing
 * the direct transaction information in the supplied object.
 * @param callback
 */
export async function zoidCallback(callback: ApiSuccessCallback) {
  try {
    await onCompleteCallback(callback);
    await onSuccessCallback(callback);
  } catch (e) {
    logger.error('Failed to handle virtual checkout callback', e);
  }
  await closeCheckout();
}

/**
 * Handles the api checkout being closed through Zoid as passed from the gateway.
 * @param data An optional message about why it's closed
 */
async function zoidClosed(data: string) {
  logger.info('Handling api checkout close', data);
  try {
    await onCloseCallback(data);
  } catch (e) {
    logger.error('Failed to handle api checkout close', e);
  }
  await closeCheckout();
}

async function zoidError(data: string[]) {
  logger.info('Handling api checkout error', data);
  try {
    await onErrorCallback(data);
  } catch (e) {
    logger.error('Failed to handle api checkout error', e);
  }
  await closeCheckout();
}

/**
 * Create the zoid component responsible for launching the display of the api checkout.
 * @param configuration
 * @param checkoutUrl
 */
function createZoidComponent(configuration: ApiCheckoutConfiguration, checkoutUrl: string) {
  // bind opened to zoidComponent for focus and close functionality
  const hideOverlay = !!configuration.hideOverlay;
  const requiresPopup = supportsPopups();

  zoidComponent = zoidComponentInit({
    url: checkoutUrl,
    zoidCallback: zoidCallback,
    zoidClosed: zoidClosed,
    zoidError: zoidError,
    bridgeUrl: `${environmentConfiguration.gatewayHost}/virtual/bridge?version=${encodeURIComponent(postRobotVersion)}`,
    forceIframe: false,
    hideOverlay: hideOverlay,
  });
  // zoid component will be rendered in the <body> (necessary for the overlay)
  const element = 'body';
  zoidComponent.render(element, requiresPopup ? 'popup' : 'iframe').catch(async (e: Error) => {
    // Ignore window closed errors as they are expected
    // https://github.com/krakenjs/zoid/issues/218
    if (e?.message?.toLowerCase() !== 'Window closed'.toLowerCase()) {
      logger.error(e);
    } else {
      logger.debug('Window closed while prerender template is active', e);
    }

    // The zoid closed likely wasn't invoked in the normal close flow (e.g. when the window was force closed)
    await zoidClosed(e.message);
  });

  // overlay and navhelper for popup only
  if (requiresPopup) {
    const $navHelper = document.querySelector('.navhelper');
    if ($navHelper) $navHelper.addEventListener('click', focusCheckout.bind(this));
  }
}

/**
 * Opens an api checkout for the specified merchant and order details.
 * @param merchantId The merchant launching the checkout.  Required.
 * @param order The order details of the checkout.  Order amount and merchant reference are required.
 *  Remaining fields are optional but improve the user experience.
 * @param configuration
 */
async function openCheckout(checkoutOptions: CheckoutOptions, signature: string, configuration: ApiCheckoutConfiguration = {}): Promise<void> {
  logger.debug('Opening checkout', checkoutOptions, signature);

  const errors = validate(checkoutOptions.merchantId, checkoutOptions.order, checkoutOptions.merchantReference, signature);
  if (errors && errors.length > 0) {
    logger.error('Error opening checkout', errors);
    return;
  }

  // get gateway url and add merchant signature to it.
  const checkoutUrl = buildGatewayUrl(checkoutOptions, signature);
  const href = checkoutUrl.href;

  logger.debug('Gateway URL', href);

  createZoidComponent(configuration, href);
}

/**
 * Sets the on success callback handler for the virtual checkout.
 * @param callback
 */
function onSuccess(callback: (result: ApiSuccessCallback) => Promise<void>): void {
  if (callback) onSuccessCallback = callback;
}

/**
 * Sets the on success callback handler for variable result objects including mfpp and shipping address
 * @param callback
 */
function onComplete(callback: (result?: ApiSuccessCallback) => Promise<void>): void {
  if (callback) onCompleteCallback = callback;
}

/**
 * Save all the data objects from the onComplete zoid callback
 */
function saveResult(result) {
  if (result) {
    window.sessionStorage.setItem(callbackResult, JSON.stringify(result));
  }
}

/**
 * Clear saved result from zoid callback
 */
function clearResult() {
  window.sessionStorage.removeItem(callbackResult);
}

/**
 * Access all the data objects from the onComplete zoid callback
 */
function getCompleteResult() {
  return (JSON.parse(sessionStorage.getItem(callbackResult)));
}

/**
 * Sets the on error callback handler for the virtual checkout.  This will be invoked in case of
 * any validation errors when opening the checkout.
 */
function onError(callback: (errorMessages: string[]) => Promise<void>): void {
  if (callback) onErrorCallback = callback;
}

/**
 * Sets the on close callback handler for the virtual checkout to handle abandoned checkouts.
 * @param callback
 */
function onClose(callback: (message: string) => Promise<void>): void {
  if (callback) onCloseCallback = callback;
}

/**
 * Focuses on the already launched virtual checkout.  If it is not launched, then any resources are closed and destroyed.
 */
function focusCheckout(): void {
  if (zoidComponent) {
    zoidComponent.focus();
  }
}

/**
 * Closes the checkout if it is open.
 */
async function closeCheckout(): Promise<void> {
  if (zoidComponent) {
    try {
      await zoidComponent.close();
    } catch (e) {
      logger.error('Unable to close virtual checkout', e);
    }
  }
}

/**
 * Validates the supplied parameters.  If they are invalid, the errors are returned
 * in an array and the onError callback is invoked.
 * @param merchantId
 * @param order
 * @param configuration
 */
function validate(merchantId: string, order: Order, merchantReference: string, signature: string, configuration?: ApiCheckoutConfiguration): string[] {
  const errors = [];
  if (!merchantId) {
    errors.push('Merchant ID is required');
  }

  if (!uuidRegex.test(merchantId)) {
    errors.push(`Merchant ID '${merchantId}' is not a valid ID`);
  }

  if (!signature) {
    errors.push('Signature is required');
  }

  if (!order) {
    errors.push('Order is required');
  } else {
    if (!order.amount || order.amount < 0) {
      errors.push('Order amount is required');
    }

    if (!merchantReference) {
      errors.push('Merchant reference is required as merchant\'s identifier for the order');
    }

    if (!order.currency) {
      errors.push('Order currency is required');
    }
  }

  if (errors.length > 0) {
    logger.warn('Unable to open api checkout due to validation errors', errors);

    try {
      onErrorCallback(errors).catch((e) => {
        logger.error('Unable to handle validation errors in error callback', e);
      });
    } catch (e) {
      logger.error('Unable to handle validation errors in error callback', e);
    }
  }

  return errors;
}

/**
 * Detects if the script is loaded within an iframe based on https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t#answer-326076.
 * The cypress hack is because Cypress makes the self and top windows match themselves.
 * Cypress also doesn't let us check for window.Cypress in iframed windows so we can't easily detect when we're executing in a cypress environment.
 * This is a hack to use an undocumented query string value we can set in our testing.  This is generally low risk as if a merchant were to do this,
 * it would just break their integration in testing anyway.
 * - https://github.com/cypress-io/cypress/issues/2664#issuecomment-434610985
 * = https://github.com/cypress-io/cypress/issues/3864#issuecomment-933303363
 */
function isInIframe(): boolean {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const cypressForceIframe = urlParams.get('cypressForceIframe') === 'true';
    return (window.self !== window.top) || cypressForceIframe;
  } catch (e) {
    return true;
  }
}

/**
 * Updates the environment configuration of virtual checkout to use the environment specified
 * @param environmentName The name of the environment to integrate with (e.g. Production, Sandbox, etc.)
 */
function updateEnvironment(environmentName: string): void {
  const currentEnvironment = environmentConfiguration;
  const loadedEnvironment = getConfigurationForEnvironmentName(environmentName);

  if (currentEnvironment?.environmentName !== loadedEnvironment?.environmentName) {
    environmentConfiguration = loadedEnvironment;
  }
}

const apiCheckoutApi: ApiCheckoutApi = {
  buildGatewayUrl,
  openCheckout,
  onSuccess,
  onComplete,
  onError,
  onClose,
  focusCheckout,
  closeCheckout,
  updateEnvironment,
  validate,
  getCompleteResult,
  saveResult,
  clearResult,
};

export default apiCheckoutApi;

/**
 * I like this pattern of exporting internal things to make them more testable.  I don't like that
 * it breaks encapsulation, but unless we rely on some rewiring which has hit-or-miss support,
 * this is the easiest way to test the non-public API.
 *
 * Inspired by:
 * https://stackoverflow.com/questions/54116070/how-to-unit-test-non-exported-functions
 */
export const testables = {
  buildGatewayUrl,
  zoidCallback,
  zoidClosed,
  zoidError,
  createZoidComponent,
  isInIframe,
};