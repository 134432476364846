import { getWidgetData } from '@/services/widget-data';
import { WidgetApi } from '../api-definitions';
import getConfiguration, { EnvironmentConfiguration, getConfigurationForEnvironmentName } from '../shared/configuration';
import logger from '../shared/logger';

let environmentConfiguration: EnvironmentConfiguration = getConfiguration();
logger.info(`Successfully instantiated the widget API in environment ${environmentConfiguration.environmentName}`);

/**
 * Displays the modal
 * @param widgetId The id to identify the modal to display
 */
function displayModal(widgetId) {
  const modalContainer = document
    .querySelector(`#qp-modal-${widgetId}`)
    .shadowRoot.getElementById('qp-modal__overlay');
  modalContainer.style.display = 'block';
  modalContainer.focus();
}

/**
 * Retrieve widget ids
 */
function getWidgetIds() {
  const tagName = getConfiguration().tagname;
  const widgets = document.querySelectorAll(tagName);
  const widgetIds = [];
  widgets.forEach((widget) =>
    widgetIds.push(
      widget.getAttribute('widgetid') || widget.getAttribute('widgetId'),
    ),
  );
  return widgetIds;
}

/**
 * Hides the modal
 */
function hideModal(widgetId): void {
  const modalContainer = document
    .querySelector(`#qp-modal-${widgetId}`)
    .shadowRoot.getElementById('qp-modal__overlay');
  const configuration = getConfiguration();

  // If our modal is open and we close it in any way, we should return focus to the link.
  if (modalContainer.style.display === 'block') {
    modalContainer.style.display = 'none';

    const widget = document.querySelector(configuration.tagname);
    if (widget) {
      const button: HTMLElement = widget.shadowRoot.querySelector('.qp-open-modal-button');
      button.focus();
    }
  }
}

/**
 * Updates the environment environmentConfigurationuration of widget to use the environment specified
 * @param environmentName The name of the environment to integrate with (e.g. Production, Sandbox, etc.)
 */
function updateEnvironment(environmentName: string): void {
  const currentEnvironment = environmentConfiguration;
  const loadedEnvironment = getConfigurationForEnvironmentName(environmentName);

  if (currentEnvironment?.environmentName !== loadedEnvironment?.environmentName) {
    environmentConfiguration = loadedEnvironment;
  }
}

function getWidgetVerbiage(attribute, isServiceFeeMerchant, isMFPPMerchant, hasFees): string {
  const WidgetVerbiageMap = Object.freeze({
    '4-easy-payments': '4 easy payments',
    '4-payments': '4 payments',
    '4-installments': 'Pay in 4 installments',
  });
  let verbiageKey: string = '4-payments';
  if (attribute === '4-installments' || (attribute === '4-easy-payments' && isMFPPMerchant)) {
    verbiageKey = attribute;
  } else if (isServiceFeeMerchant || isMFPPMerchant) {
    verbiageKey = '4-payments';
  } else if (WidgetVerbiageMap[attribute]) {
    verbiageKey = attribute;
  }
  verbiageKey = 'widget.' + verbiageKey;

  return verbiageKey;
}

async function getPromotionsVariation(): Promise<string> {
  const widgetData = getWidgetData();
  const displayPromotions = await widgetData.getABTestVariation('kh676WNtGYatLyKieth8fS', 'widget_promotions');
  return displayPromotions;
}

const widgetApi: WidgetApi = {
  displayModal,
  hideModal,
  updateEnvironment,
  getWidgetIds,
  getWidgetVerbiage,
  getPromotionsVariation,
};
export default widgetApi;

export const testables = {};
