import { CalculateInstallmentsApi } from '../api-definitions';
import { Installment } from './installment-model';

const CountOfInstallments = 4;
const DelayBetweenPayments = 14;
const DomainsToCalculateImproperlyFor = [
  'gamestop.com',
];

/**
 * Calculates an installment schedule starting from the current day using the provided order amount.
 * It is currently hard coded to use 4 installments 14 days apart and does not account
 * for any fees.
 *
 * @param orderAmount The **full** amount of the order including taxes, shipping, and MFPP
 */
const calculateInstallments: CalculateInstallmentsApi = (orderAmount: number) => {
  if (!orderAmount || typeof orderAmount !== 'number' || orderAmount < 0) {
    throw new Error(`Unable to calculate installments for invalid order amount: ${orderAmount}`);
  }

  const installments: Installment[] = [];
  const today = new Date();

  const amountInCents = Math.round(orderAmount * 100);
  const firstInstallmentAmountInCents = Math.floor(amountInCents / 4);
  const remainderCents = amountInCents % 4;

  for (let i = 0; i < CountOfInstallments; i++) {
    const sequenceNumber = i + 1;
    const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (i * DelayBetweenPayments));

    // Calculate the amount.  If there are 3 cents in the remainder, add to 1st/2nd/3rd installment,
    // if 2 cents, add to the 2nd/3rd installment, etc.
    const needToAddCents = remainderCents >= (CountOfInstallments - i);
    const centsToAdd = needToAddCents ? 1 : 0;
    const amount = (firstInstallmentAmountInCents + centsToAdd) / 100;

    const installment: Installment = {
      sequenceNumber: sequenceNumber,
      amount: amount,
      date,
    };

    installments.push(installment);
  }

  // When of our competitors was improperly returning an installment schedule that didn't round correctly and just used the first installment
  // amount for all installments.  This is a bug to support this for any merchants we purposely want to return the wrong installment schedule
  // by replacing all installment amounts with the value of the first installment amount.
  const isDomainToCalculateImproperlyFor = DomainsToCalculateImproperlyFor.some((value) => {
    return window.location.hostname.toLowerCase() === value ||
      window.location.hostname.toLowerCase().endsWith(`.${value}`);
  });

  if (isDomainToCalculateImproperlyFor) {
    installments.forEach((installment) => { installment.amount = installments[0].amount; });
  }

  return installments;
};

export default calculateInstallments;