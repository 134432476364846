import Vue from 'vue';
import { BusEvent } from './models';
import { eventsTurnedOff } from '@/utils/eventsTurnedOffDomains';
import { Options } from '../analytics';

export const viewedStandardWidgetEvent = 'viewed_standard_widget';
export const viewedPaymentWidgetEvent = 'viewed_payment_widget';
export const viewedSecondChanceWidgetEvent = 'viewed_secondchance_widget';
export const viewedLearnMoreModalEvent = 'viewed_learn_more_modal';
export const viewedCheckoutButtonEvent = 'viewed_checkout_button';
export const errorEvent = 'Virtual Checkout Exception';

export class EventBus {
  private readonly eventBus: Vue;
  private readonly queue: BusEvent[] = [];
  private isInitialised = false;
  private readonly domain: string = window?.location?.hostname;

  constructor() {
    this.eventBus = new Vue();
  }

  initialise() {
    if (this.isInitialised) return;

    this.isInitialised = true;
    this.processQueue();
  }

  publishViewedStandardWidgetEvent(
    amount: number,
    domain: string,
    path: string,
    loadingDuration: number,
    options?: Options,
    variationText?: string,
    widgetId?: string,
  ): void {
    if (!eventsTurnedOff(this.domain)) {
      this.emit(viewedStandardWidgetEvent, [
        amount,
        domain,
        path,
        loadingDuration,
        options,
        variationText,
        widgetId,
      ]);
    }
  }

  subscribeViewedStandardWidgetEvent(
    handler: (
      amount: number,
      domain: string,
      path: string,
      loadingDuration: number,
      options?: Options,
      variationText?: string,
      widgetId?: string,
    ) => void,
  ): void {
    this.eventBus.$on(viewedStandardWidgetEvent, handler);
  }

  publishViewedPaymentWidgetEvent(
    amount: number,
    domain: string,
    path: string,
    loadingDuration: number,
    options?: Options,
    widgetId?: string,
  ): void {
    if (!eventsTurnedOff(this.domain)) {
      this.emit(viewedPaymentWidgetEvent, [
        amount,
        domain,
        path,
        loadingDuration,
        options,
        widgetId,
      ]);
    }
  }

  subscribeViewedPaymentWidgetEvent(
    handler: (
      amount: number,
      domain: string,
      path: string,
      loadingDuration: number,
      options?: Options,
      widgetId?: string,
    ) => void,
  ): void {
    this.eventBus.$on(viewedPaymentWidgetEvent, handler);
  }

  publishViewedSecondChanceWidgetEvent(
    amount: number,
    domain: string,
    path: string,
    loadingDuration: number,
    options?: Options,
  ): void {
    if (!eventsTurnedOff(this.domain)) {
      this.emit(viewedSecondChanceWidgetEvent, [
        amount,
        domain,
        path,
        loadingDuration,
        options,
      ]);
    }
  }

  subscribeViewedSecondChanceWidgetEvent(
    handler: (
      amount: number,
      domain: string,
      path: string,
      loadingDuration: number,
      options?: Options,
    ) => void,
  ): void {
    this.eventBus.$on(viewedSecondChanceWidgetEvent, handler);
  }

  publishViewedLearnMoreModalEvent(
    amount: number,
    domain: string,
    path: string,
    widgetType: string,
    variationText?: string,
    widgetId?: string,
  ): void {
    if (!eventsTurnedOff(this.domain)) {
      this.emit(viewedLearnMoreModalEvent, [
        amount,
        domain,
        path,
        widgetType,
        variationText,
        widgetId,
      ]);
    }
  }

  subscribeViewedLearnMoreModalEvent(
    handler: (
      amount: number,
      domain: string,
      path: string,
      widgetType: string,
      variationText?: string,
      widgetId?: string,
    ) => void,
  ): void {
    this.eventBus.$on(viewedLearnMoreModalEvent, handler);
  }

  publishViewedCheckoutButtonEvent(
    amount: number,
    domain: string,
    path: string,
    loadingDuration: number,
  ): void {
    if (!eventsTurnedOff(this.domain)) {
      this.emit(viewedCheckoutButtonEvent, [
        amount,
        domain,
        path,
        loadingDuration,
      ]);
    }
  }

  subscribeViewedCheckoutButtonEvent(
    handler: (
      amount: number,
      domain: string,
      path: string,
      loadingDuration: number,
    ) => void,
  ): void {
    this.eventBus.$on(viewedCheckoutButtonEvent, handler);
  }

  publishErrorEvent(error: Error, handled = true, id?: string): void {
    this.emit(errorEvent, [error, handled, id]);
  }

  subscribeErrorEvent(
    handler: (error: Error, handled: boolean, id?: string) => void,
  ): void {
    this.eventBus.$on(errorEvent, handler);
  }

  private emit(eventName: string, args: any[]): void {
    if (!this.isInitialised) {
      this.queue.push({ eventName, args });
      return;
    }

    this.eventBus.$emit(eventName, ...args);
  }

  private processQueue(): void {
    while (this.queue.length) {
      const event = this.queue.shift();
      this.emit(event.eventName, event.args);
    }
  }
}

let eventBusImpl: EventBus;

export const getEventBus = (): EventBus => {
  if (!eventBusImpl) {
    eventBusImpl = new EventBus();
  }

  return eventBusImpl;
};
