import { SharedApi } from '../api-definitions';
import { MerchantFeeTierArray } from '@/services/widget-data';

/**
 * Utility to convert all installmentAmount types to clean number
*/
export function parseFullAmount(fullAmount: string | number): number {
  if (fullAmount) {
    const numbersdecimalonly = /[0-9.]/g;
    fullAmount = fullAmount.toString();
    const decimalNumber = fullAmount.match(numbersdecimalonly);
    if (decimalNumber) {
      fullAmount = decimalNumber.join('');
      return parseFloat(Number(fullAmount).toFixed(2));
    }
  }
  return 0;
}

/**
 * Utility to convert the total amount to installment amount taking service fee into account
 * This is typically used for the widget
*/
export function calcInstallmentAmount(amount: string | number, installmentFee: string | number, isServiceFeeMerchant: boolean): string {
  const installments = calcInstallmentAmounts(amount, installmentFee, isServiceFeeMerchant);
  return installments[installments.length - 1];
}

/**
 * Utility to convert the total amount to installment amount taking service fee into account
 * This is used for the Zip and Payment-Methods widget.
*/
export function calcInstallmentAmounts(amount: string | number, installmentFee: string | number, isServiceFeeMerchant: boolean): string[] {
  if (typeof amount !== 'string' && typeof amount !== 'number') {
    throw new Error(`Order amount must be in a string or number format: ${amount}`);
  }

  const installmentCount = 4;
  const decimalPlaces = 2;

  const amountInFloat = parseFullAmount(amount);
  if (amountInFloat === 0) {
    // Be careful with this implementation. JS will treat the item created as the same object!! (Referenced types)
    // This is okay because we are only displaying the values and never modifying them directly.
    return Array(installmentCount).fill(parseFloat('0').toFixed(decimalPlaces));
  } else {
    let installmentServiceFeeAmount: number = 0;
    if (isServiceFeeMerchant) {
      installmentServiceFeeAmount = parseFullAmount(installmentFee);
    }

    // Calculate the total amount with fees
    const totalAmountWithFees = amountInFloat + (installmentServiceFeeAmount * installmentCount);

    // Calculate the base installment amount
    const installmentAmount = Math.floor((totalAmountWithFees / installmentCount) * 100) / 100;

    // Create an array to store the installment amounts
    const installments = new Array<string>(installmentCount).fill(installmentAmount.toFixed(decimalPlaces));

    // Calculate the remaining pennies
    const remainingPennies = Math.floor((totalAmountWithFees * 100) % (installmentAmount * 100));

    // Distribute the remaining pennies across the installments
    for (let i = 0; i < remainingPennies; i++) {
      // Increment the amount of each installment by 0.01 to distribute the pennies
      installments[i % installmentCount] = (parseFloat(installments[i % installmentCount]) + 0.01).toFixed(decimalPlaces);
    }

    // Sort installments from cheapest to most expensive.
    // In the Checkout flow, we also sort it this way.
    installments.sort((a, b) => parseFloat(a) - parseFloat(b));

    return installments;
  }
}

export function calculateCustomerFee(amount: number, feeTiers: MerchantFeeTierArray): number {
  let maxTier = 0;
  let maxFee = 0;
  feeTiers.forEach((tier) => {
    // checking all tiers for closest match in case tiers aren't ordered correctly
    const tierAmount = tier.feeStartsAt;
    if (tierAmount <= amount) {
      if (maxTier < tierAmount) {
        maxTier = tierAmount;
        maxFee = tier.totalFeePerOrder;
      }
    }
  });

  return maxFee;
}

const sharedApi: SharedApi = {
  parseFullAmount,
  calcInstallmentAmount,
  calcInstallmentAmounts,
};
export default sharedApi;

export const testables = {};