export interface Options {
  widgetType?: WidgetType
  alignment?: string
  breakpointAlignment?: string
  customMargins?: string
  disableAdaCompliance?: boolean
  displayMode?: string
  hideLogo?: boolean
  isMFPPMerchant?: boolean
  languageSelector?: string
  learnMoreUrl?: string
  logoAlignment?: string
  logoOption?: string
  logoSize?: string
  max?: number
  min?: number
  minModal?: string
  priceColor?: string
  size?: string
  widgetVerbiage?: string

  // Payment widget specific fields
  paymentWidgetMFPP?: boolean
  hideHeader?: boolean
  hideSubtitle?: boolean
  hideTimeline?: boolean
  timelineColor?: string
}

export enum WidgetType {
  Standard = 'STANDARD',
  Payment = 'PAYMENT',
  SecondChance = 'SECOND_CHANCE',
}