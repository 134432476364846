import getConfiguration, { getConfigurationForEnvironmentName } from '../shared/configuration';
import { MerchantFeesApi } from '../api-definitions';
import { CalculateMerchantFeeForPaymentPlanRequest, CalculateMerchantFeeForPaymentPlanResponse } from './models';

/**
 * Calculates the MFPP (merchant fee for payment plan) for a given order.  Merchant must provide their ID,
 * order amount, and customer state.  Currency and country are encouraged by defaulted to the US if not supplied.
 *
 * If territory is not supplied, it is also defaulted to the US.
 */
async function calculateMerchantFeeForPaymentPlan(request: CalculateMerchantFeeForPaymentPlanRequest, environmentName?: string): Promise<CalculateMerchantFeeForPaymentPlanResponse> {
  // Set defaults
  if (!request.currency) {
    request.currency = 'USD';
  }

  if (!request.territory) {
    request.territory = 'US';
  }

  if (!request.customerCountry) {
    request.customerCountry = 'US';
  }

  // Request validation
  if (!request) {
    throw new Error('Calculate merchant fee for payment plan request is required');
  } else if (!request.amount || typeof request.amount !== 'number') {
    // Will throw an error if the amount is 0, falsy, or not a number.
    throw new Error(`Order amount must be a valid number: ${request.amount}`);
  } else if (!request.merchantId) {
    throw new Error('Merchant ID is required');
  } else if (!request.customerState) {
    throw new Error('Customer state is required');
  }

  // Return 0 value if the merchant passed in a request amount <= to 0.
  // Realistically, we should be throwing an error but merchants have been using this API for a while now.
  // We don't want to break existing integrations by throwing an error that isn't expected.
  // Once we move over to WebBank, this API can start throwing errors because it should be useless then.
  if (request.amount <= 0) {
    const badAmountResponse: CalculateMerchantFeeForPaymentPlanResponse = {
      merchantFeeForPaymentPlan: 0,
      adjustedOrderAmount: request.amount,
      currency: request.currency,
    };

    return badAmountResponse;
  }

  const configuration = environmentName ? getConfigurationForEnvironmentName(environmentName) : getConfiguration();

  const body = {
    currency: request.currency,
    merchantId: request.merchantId,
    customerState: request.customerState,
    customerCountry: request.customerCountry,
    amount: request.amount,
  };
  const url = `${configuration.gatewayHost}/orders/calculate-merchant-fees/`;

  const response = await fetch(url, {
    method: 'post',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'QP-Territory': request.territory,
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  });

  const result = await response.json() as CalculateMerchantFeeForPaymentPlanResponse;
  return result;
}

const merchantApi: MerchantFeesApi = {
  calculateMerchantFeeForPaymentPlan,
};

export default merchantApi;