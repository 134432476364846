import logger from '@/shared/logger';
import { ShippingLocation, ShippingPreference } from '@/sdk-checkout-integration/sdk-integration-models';

export function convertShippingPreferenceToJSON(shippingPreference: ShippingPreference): undefined | null | string {
  if (!shippingPreference) {
    return;
  }

  const shippingPreferenceAsJSON = JSON.stringify(shippingPreference);
  // Validate JSON
  try {
    const parsed = JSON.parse(shippingPreferenceAsJSON);

    // If parsed value is not an object, we know that the type is incorrect.
    if (typeof parsed !== 'object') {
      // Do nothing
    } else {
      // Return JSON.
      return shippingPreferenceAsJSON;
    }
  } catch (e) {
    // Fail silently
    logger.error('Shipping Locations not formatted correctly. Unable to convert to JSON.');
  }
}

export function isShippingLocationsValidAndNotEmpty(shippingLocations: ShippingLocation[]): boolean {
  try {
    if (shippingLocations?.length > 0) {
      return shippingLocations.every((location) => {
        // Country must exist and contain 2 characters.
        if (!location?.country || location.country.trim().length !== 2) {
          logger.error('The country must be two characters');
          return false;
        }

        // When the state exists, the character count must be 2.
        if (location?.state && location.state.trim().length !== 2) {
          logger.error('The state must be two characters.');
          return false;
        }

        return true;
      });
    }

    return false;
  } catch (e) {
    // Fail silently
    logger.error('Shipping Locations is not valid.');
    return false;
  }
}

export function getShippingPreference(allowedShippingLocations?: string, deniedShippingLocations?: string, deniedPOBoxShippingLocations?: string): ShippingPreference {
  const shippingPreference: ShippingPreference = {
    allowedShippingLocations: [],
    deniedShippingLocations: [],
    deniedPOBoxShippingLocations: [],
  };

  // Format allowed shipping locations.
  shippingPreference.allowedShippingLocations = formatShippingLocations(allowedShippingLocations);

  // If 0 allowed locations, then check for denied locations.
  if (shippingPreference.allowedShippingLocations.length === 0) {
    // Format denied shipping locations.
    shippingPreference.deniedShippingLocations = formatShippingLocations(deniedShippingLocations);
  }

  // Format PO Box shipping locations.
  shippingPreference.deniedPOBoxShippingLocations = formatShippingLocations(deniedPOBoxShippingLocations);

  return shippingPreference;
}

/**
 * Parse ShippingLocations to ShippingLocation collection or undefined
 */
export function formatShippingLocations(shippingLocations?: string): ShippingLocation[] | undefined {
  const emptyShippingLocations: ShippingLocation[] = [];

  try {
    if (shippingLocations == null || shippingLocations.trim() === '') {
      return emptyShippingLocations;
    }

    const splitLocations = shippingLocations.split(',');
    const formattedLocations: ShippingLocation[] = [];

    for (const location of splitLocations) {
      const stateAndCountry = location.trim().split('-');
      if (stateAndCountry.length === 2) {
        const state = stateAndCountry[0].trim();
        const country = stateAndCountry[1].trim();

        if (state.length !== 2 || country.length !== 2) {
          logger.error('State or country not formatted correctly.');
          continue;
        }

        const shippingLocation: ShippingLocation = {
          state: state,
          country: country,
        };
        formattedLocations.push(shippingLocation);
      } else if (stateAndCountry.length === 1) {
        const country = stateAndCountry[0].trim();
        if (country.length !== 2) {
          logger.error('Country not formatted correctly.');
          continue;
        }
        const shippingLocation: ShippingLocation = {
          country: country,
        };
        formattedLocations.push(shippingLocation);
      }
    }

    return formattedLocations;
  } catch (e) {
    // Fail silently
    logger.error('Shipping Locations not formatted correctly.');
    return emptyShippingLocations;
  }
}