import getConfiguration, { getConfigurationForEnvironmentName } from '../shared/configuration';
import { datadogRum } from '@datadog/browser-rum';
import logger from '../shared/logger';

export const ContextName = 'QuadpayJS_Context';
let initialized = false;

export function initializeDatadog(environmentName?: string) {
  const configuration = environmentName ? getConfigurationForEnvironmentName(environmentName) : getConfiguration();

  const whitelist = configuration.serviceDomains.map((element) => new RegExp(`https://(.*).${element}`));

  const ddRumConfig = {
    applicationId: configuration.datadogApplicationId,
    clientToken: configuration.datadogClientToken,
    datacenter: configuration.datadogDatacenter as any,
    env: configuration.environmentName,
    version: (process.env as any).APPLICATION_VERSION,
    sampleRate: 100,
    premiumSampleRate: 0,
    service: configuration.datadogServiceName,
    silentMultipleInit: true,
    allowedTracingOrigins: whitelist,
    site: 'us3.datadoghq.com',
  };
  datadogRum.init(ddRumConfig);
  initialized = true;
}

const datadogApi = {
  addError: (error: unknown, context?: object, source?: 'custom' | 'network' | 'source') => {
    if (!initialized) {
      logger.warn('Attempting to use datadog when it isn\'t initialized');
      return;
    }

    datadogRum.addError(error, context);
  },
  addRumGlobalContext: (key: string, value: any) => {
    if (!initialized) {
      logger.warn('Attempting to use datadog when it isn\'t initialized');
      return;
    }

    datadogRum.addRumGlobalContext(key, value);
  },
};

export default datadogApi;