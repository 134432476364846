import getConfiguration, { getConfigurationForEnvironmentName } from '../shared/configuration';
import { AssociateApi } from '../api-definitions';

/**
 * Associates the platform order ID to the order denoted by the merchant reference.  Optionally adds line item details.
 * @param merchantReference The merchant's original order ID used to start the checkout
 * @param merchantPlatformId The merchant's actual order ID determined after the checkout
 * @param lineItems An array of line items that were purchased.
 */
const associate: AssociateApi = async (merchantReference: string, merchantPlatformId: string, lineItems?: any[], environmentName?: string) => {
  // Return if merchant platform Id is null.
  // Downstream, the Orders service will return if MerchantPlatformId is equal to the current MerchantPlatformId.
  // The current MerchantPlatformId should be null.
  if (!merchantPlatformId) {
    return;
  }

  const configuration = environmentName ? getConfigurationForEnvironmentName(environmentName) : getConfiguration();

  const body = {
    merchantReference: merchantReference,
    merchantPlatformId: merchantPlatformId,
    lineItems: lineItems,
  };
  const url = `${configuration.gatewayHost}/orders/associate/`;

  await fetch(url, {
    method: 'post',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  });
};

export default associate;