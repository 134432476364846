/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import Vue from 'vue';
import wrap from '@vue/web-component-wrapper';
import getConfiguration from '@/shared/configuration';

const Widget = async () => await import(/* webpackChunkName: "Widget" */'./assets/components/Widget.vue');
const PaymentMethods = async () => await import(/* webpackChunkName: "PaymentWidget" */'./assets/components/PaymentMethods.vue');

const configuration = getConfiguration();

// define custom element
window.customElements.define(configuration.tagname, wrap(Vue, Widget) as any);
window.customElements.define(
  configuration.paymentMethodsTagName,
  wrap(Vue, PaymentMethods) as any,
);

Vue.config.devtools = false;
Vue.config.productionTip = false; // removed in Vue 3 per https://v3-migration.vuejs.org/breaking-changes/global-api.html#config-productiontip-removed