import logger from '@/shared/logger';
import Translator from '@/shared/translator';
import { supportsPopups } from 'belter';
import { create } from 'zoid';
import iframeCss from './assets/styles/iframes.scss';
import overlayCss from './assets/styles/overlay.scss';
import qpIframeOverlayTemplate from './assets/templates/iframe-overlay-template.html';
import qpLoadingTemplate from './assets/templates/loading-template.html';
import popupOverlayTemplate from './assets/templates/popup-overlay-template.html';

export function prerenderTemplate(opts) {
  const { doc } = opts;
  const html: Element = doc.createElement('html');
  const template = doc.createElement('div');
  template.innerHTML = `<style>${overlayCss}</style>${qpLoadingTemplate}`;
  html.appendChild(template);
  return html;
};

const tagName = 'quadpay-checkout';

export function containerTemplate(opts) {
  const { doc, uid, frame, prerenderFrame, props } = opts;
  const template: Element = doc.createElement('div');
  const isIframe = props.forceIframe || !supportsPopups();

  if (!isIframe && props.hideOverlay) {
    // No iframe, hide overlay
    return template;
  } else if (!isIframe) {
    // No iframe, show overlay
    // overlay with navhelper
    template.id = uid;
    template.innerHTML = `<style>${overlayCss}</style>${popupOverlayTemplate}`;
    const translator = new Translator();
    translator.translateContainer(template).catch(e => logger.error('Failed to translate template', e));
    return template;
  }

  // iframe handling
  // iframe size defaults to 300 x 150
  const css = doc.createElement('style');
  css.type = 'text/css';

  // height 100% sets to 0 px, max-content sets to 150px
  // isolate iframe styling just to this zoid component
  const modifiedIframeCss = `div[id^='zoid-${tagName}'] > ${iframeCss}`;
  if (css.styleSheet) css.styleSheet.cssText = modifiedIframeCss;
  else css.appendChild(doc.createTextNode(modifiedIframeCss));
  doc.getElementsByTagName('head')[0].appendChild(css);

  // overlay without navhelper
  if (!props.hideOverlay) template.innerHTML = `<style>${overlayCss}</style>${qpIframeOverlayTemplate}`;

  template.id = uid;
  template.appendChild(frame);
  template.appendChild(prerenderFrame);
  (template.lastElementChild as any).style.display = 'none';
  return template;
};
/**
 * The zoid component init holds the function that will create overlay and the popup/iframe contents
 */
export default create({
  // tag-name for component, used for loading the correct component in the child window, etc.
  tag: tagName,
  // the full url that will be loaded when the child component is rendered
  url: ({ props }) => {
    return props.url;
  },
  dimensions: {
    height: '837px',
    width: '390px',
  },
  props: {
    url: {
      type: 'string',
      required: true,
    },
    zoidCallback: {
      type: 'function',
      required: false,
    },
    zoidClosed: {
      type: 'function',
      required: false,
    },
    zoidError: {
      type: 'function',
      required: false,
    },
    bridgeUrl: {
      type: 'string',
      required: true,
    },
    forceIframe: {
      type: 'boolean',
      required: false,
    },
    hideOverlay: {
      type: 'boolean',
      required: false,
    },
  },
  // loaded in place of child (popup/iframe) before child is done
  prerenderTemplate: prerenderTemplate,
  // loaded on parent window (merchant site)
  containerTemplate: containerTemplate,
  bridgeUrl: ({ props }) => {
    return props.bridgeUrl;
  },
});