import { PaymentMethodsApi } from '../api-definitions';
import getConfiguration, { EnvironmentConfiguration } from '../shared/configuration';
import logger from '../shared/logger';

const environmentConfiguration: EnvironmentConfiguration = getConfiguration();
logger.info(`Successfully instantiated the Payment Methods API in environment ${environmentConfiguration.environmentName}`);

/**
 * Displays the modal
 * @param widgetId The id to identify the modal to display
 */
function displayModal(widgetId) {
  const modalContainer = document
    .querySelector(`#qp-modal-${widgetId}`)
    .shadowRoot.getElementById('qp-modal__overlay');
  modalContainer.style.display = 'block';
  modalContainer.focus();
};

const paymentMethodsApi: PaymentMethodsApi = {
  displayModal,
};
export default paymentMethodsApi;

export const testables = {};