/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/dot-notation */
import logger from '@/shared/logger';
import { _createModal } from '@/widget/quadpay-modal';
import Modal from '@/widget/assets/components/BaseModal.vue';
import baseModalScss from '@/widget/assets/styles/base-modal.scss';
import { BaseModalOptions } from '@/widget/widget-models';
import { parseFullAmount } from '@/widget/shared-api';
import widgetApi from '@/widget/widget-api';
import { getWidgetData } from '@/services/widget-data';
import { BankPartner } from '@/services/widget-data/models';

export default async function insertModal(merchantId: string, options?: Object) {
  try {
    // use merchant id as widget ID so they only have one modal per page
    const modalComponent = await _createModal('base', Modal, baseModalScss, merchantId);

    // making sure options are in the correct format for the modal
    const formattedOptions = await formatModalOptions(merchantId, options || {});
    Object.keys(formattedOptions).forEach((propName) => {
      modalComponent[propName] = formattedOptions[propName];
    });

    widgetApi.displayModal(merchantId);
  } catch (error) {
    logger.error(error);
  }
}

// Most of this code is redundant to how all these props are formatted in Widget.vue
// This could be refactored to make an external formatWidgetProps() util
async function formatModalOptions(merchantId: string, options: Object): Promise<BaseModalOptions> {
  // format the merchant's passed in "options" as the correct format for the modal
  const formattedOptions: any = {};

  const isServiceFeeMerchant = options['isServiceFeeMerchant'] || options['isServiceFeeMerchant'.toLowerCase()];
  formattedOptions.isServiceFeeMerchant = [true, 'true'].includes(isServiceFeeMerchant);

  const isMFPPMerchant = options['isMFPPMerchant'] || options['isMFPPMerchant'.toLowerCase()];
  formattedOptions.isMFPPMerchant = [true, 'true'].includes(isMFPPMerchant);

  const serviceFeeAmount = options['installmentServiceFeeAmount'] || options['installmentServiceFeeAmount'.toLowerCase()] || 1.0;
  formattedOptions.serviceFeeAmount = parseFullAmount(serviceFeeAmount);

  const disableAdaCompliance = options['disableAdaCompliance'] || options['disableAdaCompliance'.toLowerCase()];
  formattedOptions.disableAdaCompliance = [true, 'true'].includes(disableAdaCompliance);

  formattedOptions.minimumOrderAmount = options['minimumOrderAmount'] || options['minimumOrderAmount'.toLowerCase()] || 35;
  formattedOptions.learnMoreUrl = options['learnMoreUrl'] || options['learnMoreUrl'.toLowerCase()];

  const implementCloseModal = options['implementCloseModal'] || options['implementCloseModal'.toLowerCase()];
  formattedOptions.implementCloseModal = [true, 'true'].includes(implementCloseModal);
  formattedOptions.widgetId = merchantId;

  const isMobileSDK = options['isMobileSDK'] || options['isMobileSDK'.toLowerCase()];
  formattedOptions.isMobileSDK = [true, 'true'].includes(isMobileSDK);

  const hasFees = options['hasFees'] || options['hasFees'.toLowerCase()];
  formattedOptions.hasFees = [true, 'true'].includes(hasFees);

  const attribute = options['widgetVerbiage'] || options['widgetVerbiage'.toLowerCase()];
  formattedOptions.modalSubtitle = widgetApi.getWidgetVerbiage(attribute, formattedOptions.isServiceFeeMerchant, formattedOptions.isMFPPMerchant, formattedOptions.hasFees);
  formattedOptions.shouldShowWebbankDisclosure = options['bankPartner'] === BankPartner.WebBank;

  let currency = 'USD';
  if (options['currency'] == 'USD' || options['currency'] == 'GBP' || options['currency'] == 'CAD') {
    currency = options['currency'];
  }
  formattedOptions.currency = currency;

  const selector = options['languageSelector'];
  if (selector && document.querySelector(selector)?.getAttribute('lang')) {
    formattedOptions.translatorLanguage = document.querySelector(selector).getAttribute('lang');
  } else if (document.querySelector('html')?.getAttribute('lang')) {
    formattedOptions.translatorLanguage = document.querySelector('html').getAttribute('lang');
  }

  const merchantName = getWidgetData().getMerchantName();
  formattedOptions.merchantName = merchantName !== '' ? merchantName : options['merchantName'] || options['merchantName'.toLowerCase()];

  const shouldCheckoutRemoveExcludedStatesDisclosures = options['shouldCheckoutRemoveExcludedStatesDisclosures'] || options['shouldCheckoutRemoveExcludedStatesDisclosures'.toLowerCase()];
  formattedOptions.shouldCheckoutRemoveExcludedStatesDisclosures = [true, 'true'].includes(shouldCheckoutRemoveExcludedStatesDisclosures);
  formattedOptions.shouldShowWebbankFeeDisclosure = options['bankPartner'] === BankPartner.WebBank && formattedOptions.hasFees;

  return formattedOptions as BaseModalOptions;
}
