import getConfiguration, {
  EnvironmentConfiguration,
} from '@/shared/configuration';
import logger from '@/shared/logger';
import { IdentifierResult, PaymentSchedulesResult, WidgetDataModel } from './models';

export class Gateway {
  private readonly configuration: EnvironmentConfiguration;

  constructor() {
    this.configuration = getConfiguration();
  }

  async fetchWidgetData(userId: string, merchantId?: string, websiteUrl?: string, orderAmount?: string): Promise<WidgetDataModel> {
    try {
      const queryParams = new URLSearchParams({
        userId,
        merchantId,
        websiteUrl,
        environmentName: this.configuration.environmentName,
        orderAmount,
      }).toString();
      const url = `${this.configuration.gatewayHost}/virtual/widget-data?${queryParams}`;

      const res = await fetch(url, {
        method: 'get',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'origin',
      });

      return await res.json();
    } catch (err) {
      logger.error('Failed to fetch merchant configs and feature flags', err);
      return null;
    }
  }

  /**
   * Calls the QP Gateway to get server fingerprint
   */
  async getIdentifier(): Promise<IdentifierResult> {
    try {
      const url = `${this.configuration.gatewayHost}/analytics`;
      const res = await fetch(url, {
        method: 'get',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'origin',
      });
      return (await res.json()) as IdentifierResult;
    } catch (err) {
      logger.error('Failed to get server fingerprint', err);
    }

    return null;
  }

  async fetchPaymentSchedules(merchantId: string, orderAmount: string): Promise<PaymentSchedulesResult> {
    try {
      const queryParams = new URLSearchParams({
        MerchantId: merchantId,
        OrderAmount: orderAmount,
      }).toString();

      const url = `${this.configuration.gatewayHost}/virtual/widget-payment-schedules?${queryParams}`;
      const res = await fetch(url, {
        method: 'get',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'origin',
      });
      return (await res.json()) as PaymentSchedulesResult;
    } catch (err) {
      logger.error('Failed to get payment schedules', err);
    }
  }
}

let gateway: Gateway;

export const getGateway = () => {
  if (!gateway) {
    gateway = new Gateway();
  }
  return gateway;
};