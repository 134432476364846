import LightLogo from '@/widget/assets/svgs/zip-logo-white.svg';
import DarkLogo from '@/widget/assets/svgs/zip-logo-dark.svg';
import InfoIcon from '@/widget/assets/svgs/info-icon.svg';
import InfoIconWhite from '@/widget/assets/svgs/info-icon-white.svg';
import Styles from './styles.scss';

// Builds HTML template for widget based on properties
export default function getSecondChanceWidgetTemplate(
  firstInstallmentAmount: string,
  backgroundColor: string,
  borderStyle: string,
  shouldHideBorder: string,
  checkoutButtonProperties: Array<Record<string, string>>,
) {
  const logo = backgroundColor.toLowerCase() === 'black' ? LightLogo : DarkLogo;
  const info = backgroundColor.toLowerCase() === 'black' ? InfoIconWhite : InfoIcon;

  const template = /* html */ `
    <style>${Styles}</style>
    <div class="zip-second-chance-widget ${getWidgetClasses(backgroundColor, borderStyle, shouldHideBorder)}">
      <h3 data-i18n="secondChanceWidget.title">Split your order in 4 easy payments with Zip.</h3>
      <p>
        <span id="zip-installment-message">
          <span class="no-wrap installment-amount">
            <span data-i18n="currency.number"></span><span id="zip-first-installment-amount">${firstInstallmentAmount}</span>
          </span>
          <span data-i18n="secondChanceWidget.subtitle-1">due today when you pay over time</span>
        </span>
        <span id="zip-min-message" class="hidden">
          Pay over time on orders over
          <span class="installment-amount">
            <span data-i18n="currency.number"></span>35
          </span>
        </span>
        <span id="zip-max-message" class="hidden">
          Pay over time on orders below
          <span class="installment-amount">
            <span data-i18n="currency.number"></span>1500
          </span>
        </span>
        <span
          class="row no-wrap"
        >
          <span data-i18n="secondChanceWidget.subtitle-2">with</span>
          <span id="zip-logo" class="zip-logo">${logo}</span>
          <span id="zip-info-icon" class="info-icon">${info}</span>
        </span>
      </p>
      ${getButtonHtml(checkoutButtonProperties)}
    </div>
  `;

  return template;
}

const getWidgetClasses = (backgroundColor, borderStyle, shouldHideBorder) => {
  let backgroundClass = '';
  if (backgroundColor.toLowerCase() === 'black') {
    backgroundClass = 'background--black';
  } else if (['gray', 'grey'].includes(backgroundColor.toLowerCase())) {
    backgroundClass = 'background--gray';
  }

  let borderStyleClass = '';
  if (borderStyle.toLowerCase() === 'curved') {
    borderStyleClass = 'border--curved';
  }

  let hideBorderClass = '';
  if (shouldHideBorder.toLowerCase() === 'true') {
    hideBorderClass = 'border--hidden';
  }

  return `${backgroundClass} ${borderStyleClass} ${hideBorderClass}`;
};

const getButtonHtml = (buttonProperties) => {
  // map button properties into html usable string
  let buttonPropertiesString = '';
  buttonProperties.forEach((property) => {
    buttonPropertiesString += ` ${property.name}="${property.value}"`;
  });

  const buttonHTML = /* HTML */ `<zip-button ${buttonPropertiesString}/>`;

  return buttonHTML;
};