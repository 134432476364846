import logger from '@/shared/logger';

const supportedLanguages = ['en-US', 'en-GB', 'en-CA', 'es-MX', 'fr-CA'];
const languageMap = new Map()
  .set('es', 'es-MX')
  .set('fr', 'fr-CA');

export function getLanguage(language?: string) {
  let languageToReturn = '';

  if (language) {
    languageToReturn = language;
  } else if (navigator.languages) {
    languageToReturn = navigator.languages[0];
  } else {
    // In some browsers, navigator.language is not guaranteed to equal navigator.languages[0]
    languageToReturn = navigator.language;
  }

  if (supportedLanguages.includes(languageToReturn)) {
    return languageToReturn;
  }

  // May be the correct language but an unsupported locale
  languageToReturn = languageMap.get(getLanguageCode(languageToReturn));
  if (supportedLanguages.includes(languageToReturn)) {
    return languageToReturn;
  }

  logger.warn(`${languageToReturn} not supported. Defaulting to en-US`);
  return 'en-US';
}

function getLanguageCode(locale: string) {
  if (locale?.includes('-')) {
    return locale.substring(0, locale.indexOf('-'));
  }

  return locale;
}

class Translator {
  private readonly supportedCurrencies = ['USD', 'GBP', 'CAD'];
  private readonly currencyMap = new Map()
    .set('USD', 'en-US')
    .set('CAD', 'en-CA');

  private language: string;
  private currency: string;
  private translation: Record<string, string> = {};

  constructor() {
    this.language = getLanguage();
  }

  get getCurrentLanguage(): string {
    return this.language;
  }

  setLanguage(language?: string) {
    this.language = getLanguage(language);
  }

  async translateComponent(currencyCode, component, language = null) {
    if (language) {
      this.language = language;
    }

    if (this.supportedCurrencies.includes(currencyCode)) {
      this.currency = currencyCode;
    } else {
      this.currency = 'USD';
    }

    this.translation = await this.getTranslationObject(this.language);
    const elements = [
      ...component.shadowRoot.querySelectorAll('[data-i18n]'),
      ...document.querySelectorAll('[data-i18n]'),
    ];

    this.translate(this.translation, elements);
  }

  async translateContainer(root: Element, language = null) {
    if (language) {
      this.language = language;
    }

    this.translation = await this.getTranslationObject(this.language);
    const elements = [...root.querySelectorAll<HTMLElement>('[data-i18n]')];

    this.translate(this.translation, elements);
  }

  translate(translation, elements: HTMLElement[]) {
    for (const element of elements) {
      let text;
      const keys = (element).dataset.i18n.split('.');

      // confirm translation property or default to en-US
      if (!(keys[0] in translation)) {
        this.getTranslationObject('en-US')
          .then((translation) => {
            text = keys.reduce((obj, i) => obj[i], translation);
          })
          .catch((err) => {
            logger.error(err);
          });
      } else {
        text = keys.reduce((obj, i) => obj[i], translation);
      }

      if (text) {
        element.innerHTML = text;
      }

      // specify currency
      if (keys.includes('currency')) {
        const currencyCode = this.currencyMap.get(this.currency);

        if (currencyCode) {
          this.getTranslationObject(currencyCode)
            .then((translation) => {
              element.innerHTML = translation?.currency?.number;
            })
            .catch((err) => {
              logger.error(err);
            });
        }
      }
    }
  }

  getSingleTranslation(compositeKey: string) {
    const keys = compositeKey.split('.');
    const text = keys.reduce<any>((obj, i) => obj[i], (this.translation));
    if (text) {
      return text;
    };
  }

  async getTranslationObject(locale) {
    const module = await import(`./locales/${locale}.json`);
    return module.default;
  };
}
export default Translator;